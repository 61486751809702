<template>
    <div>
      
        <v-tabs v-model="tab" background-color="grey darken-3 accent-4" centered dark icons-and-text>
            <v-tabs-slider></v-tabs-slider>
            <v-tab href="#tab-1">
                Atencion
                <v-icon>mdi-calendar</v-icon>
            </v-tab>
            <v-tab href="#tab-2">
                Laboratorios
                <v-icon>mdi-format-color-fill</v-icon>
            </v-tab>
            <v-tab href="#tab-3">
                Procedimientos
                <v-icon>mdi-briefcase-account</v-icon>
            </v-tab>
            <v-tab href="#tab-4">
                Graficas
                <v-icon>mdi-chart-areaspline</v-icon>
            </v-tab>
            <v-tab href="#tab-5">
                Historico
                <v-icon>mdi-history</v-icon>
            </v-tab>
            <template v-if="habanexo">
              <v-tab href="#tab-6">
                  Anexos
                  <v-icon>mdi-folder-file</v-icon>
              </v-tab>
              <v-tab href="#tab-7">
                  Historico Anexos
                  <v-icon>mdi-folder-file</v-icon>
              </v-tab>
             </template>
             <v-tab :href="habanexo? '#tab-8':'#tab-6'">
                  Alertas del paciente
                  <v-icon>mdi-account-star</v-icon>
              </v-tab>
        </v-tabs>
        
        <AlertasPaciente v-if="paciente" :coppId="paciente.tblConfPersonasnaturale.tblConfPersona.copeId"></AlertasPaciente>
        <v-tabs-items v-model="tab" class="bg-tab">
            <v-tab-item value="tab-1">
                <v-container>
                    <v-row>
                        <v-col cols="12" md="12">
                            <v-card class="md-12">
                                <v-col cols="12" sm="12" md="12" v-if="paciente">
                                    <v-row no-gutters>
                                        <v-col cols="12" sm="12" md="12" class="text-center">
                                            <span class="headline green--text"> {{factura.tblCitaCita.tblConfContratosxservicios[0].tblConfServicios[0].coseNombre}}</span>
                                        </v-col>
                                        <v-col cols="12" sm="12" md="12">
                                            <span class="headline green--text">DATOS DEL PACIENTE</span>
                                        </v-col>
                                        <v-col cols="12" sm="12" md="12">
                                            <label class="primary--text">FECHA DE ATENCION: </label>
                                            {{factura.tblCitaCita.citaFecha}}
                                        </v-col>
                                        <v-col cols="12" sm="12" md="12">
                                            <label class="primary--text">FECHA DE NACIMIENTO: </label>
                                            {{paciente.coppFechanacimiento}}
                                        </v-col>
                                        <v-col cols="12" sm="12" md="4">
                                            <label class="primary--text">NOMBRE: </label>
                                            {{paciente.tblConfPersonasnaturale.copnNombre1 + ' ' + paciente.tblConfPersonasnaturale.copnNombre2 + ' ' + paciente.tblConfPersonasnaturale.copnApellido1 + ' ' + paciente.tblConfPersonasnaturale.copnApellido2}}
                                        </v-col>
                                        <v-col cols="12" sm="12" md="4">
                                            <label class="primary--text">IDENTIFICACION: </label>
                                            {{(((paciente.tblConfPersonasnaturale.tblConfPersona.tipoidentificacion)?paciente.tblConfPersonasnaturale.tblConfPersona.tipoidentificacion.comaNombrecorto:'') +' - '+ paciente.tblConfPersonasnaturale.tblConfPersona.copeIdentificacion)}}
                                        </v-col>
                                        <v-col cols="12" sm="12" md="4" v-if="paciente.coppFechanacimiento">
                                            <label class="primary--text">EDAD: </label>
                                            {{ calcularEdad(paciente.coppFechanacimiento)}}
                                        </v-col>
                                        <v-col cols="12" sm="12" md="4">
                                            <label class="primary--text">PAIS: </label>
                                            {{((paciente.tblConfPersonasnaturale.tblConfPersona.pais)?paciente.tblConfPersonasnaturale.tblConfPersona.pais.comaNombrelargo:'')}}
                                        </v-col>
                                        <v-col cols="12" sm="12" md="4">
                                            <label class="primary--text">DEPARTAMENTO: </label>
                                            {{((paciente.tblConfPersonasnaturale.tblConfPersona.departamento)?paciente.tblConfPersonasnaturale.tblConfPersona.departamento.comaNombrelargo:'')}}
                                        </v-col>
                                        <v-col cols="12" sm="12" md="4">
                                            <label class="primary--text">MUNICIPIO: </label>
                                            {{((paciente.tblConfPersonasnaturale.tblConfPersona.municipio)?paciente.tblConfPersonasnaturale.tblConfPersona.municipio.comaNombrelargo:'')}}
                                        </v-col>
                                        <v-col cols="12" sm="12" md="4">
                                            <label class="primary--text">DIRECCION: </label>
                                            {{paciente.tblConfPersonasnaturale.tblConfPersona.copeDireccion}}
                                        </v-col>
                                        <v-col cols="12" sm="12" md="4">
                                            <label class="primary--text">BARRIO: </label>
                                            {{paciente.coppTipoBarrio}}
                                        </v-col>
                                        <v-col cols="12" sm="12" md="4">
                                            <label class="primary--text">TELEFONO: </label>
                                            {{paciente.tblConfPersonasnaturale.tblConfPersona.copeTelefono}}
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-card>
                        </v-col>
                        <v-col cols="12" md="12">
                            <v-form @submit.prevent="agregar">
                                <v-stepper v-model="e1">
                                    <v-stepper-header>
                                        <v-stepper-step :editable="true" :complete="e1 > 1" step="1">Historia</v-stepper-step>
                                        <v-divider></v-divider>
                                        <v-stepper-step :editable="true" :complete="e1 > 2" step="2" @click.stop="cargarTodo()" v-if="!$route.params.triage">Finalizar</v-stepper-step>
                                    </v-stepper-header>
                                    <v-stepper-items class="grey lighten-3">
                                        <v-stepper-content step="1">
                                            <v-divider></v-divider>
                                            <v-card-actions>
                                                <!-- <v-btn color="primary darken-1"  dark :loading="loading2" v-if="e1==1 && pag > 1" @click="atras">atras</v-btn> -->
                                                <v-pagination circle v-model="pag" @input="cargar" @next="cargar" @previous="cargar" :total-visible="10" :length="seccionesPaginado.length"></v-pagination>
                                                <!-- <v-btn color="primary darken-1" dark :loading="loading2" v-if="e1==1 && pag < seccionesPaginado.length" @click="siguiente">siguiente</v-btn> -->
                                                <div class="flex-grow-1"></div>
                                                <v-btn color="red darken-1" text v-if="e1==1" @click="cancelar">Cancelar</v-btn>
                                                <v-btn color="primary darken-1" dark v-if="e1==1 && pag == seccionesPaginado.length" :loading="loading" @click="agregarHistoria">Guardar y siguiente</v-btn>
                                            </v-card-actions>
                                            <v-divider></v-divider>
                                            <v-container class="mb-12 grey lighten-3" v-if="seccionesvista.length > 0 && !loading2">

                                                <v-row>
                                                    <input type="hidden" id="edadDias" name="edadDias" :value="(new Date().getTime() -  new Date(paciente.coppFechanacimiento.replace(/-+/g, '/')).getTime())/(1000*60*60*24)">
                                                    <input type="hidden" id="edadMeses" name="edadMeses" :value="Math.trunc((new Date().getTime() -  new Date(paciente.coppFechanacimiento.replace(/-+/g, '/')).getTime())/2629800000 )">
                                                    <input type="hidden" id="edad" name="edad" :value="new Date().getFullYear() -  new Date(paciente.coppFechanacimiento.replace(/-+/g, '/')).getFullYear()">
                                                    <input type="hidden" id="sexo" name="sexo" :value="paciente.sexo.comaNombrecorto">
                                                    <input type="hidden" id="isNew" name="isNew" :value="isNew">
                                                    <v-col cols="12" sm="12" md="12">
                                                        <v-autocomplete outlined :error="$v.causaexterna.$error" required v-model="causaexterna" :items="tipocausaexterna" item-text="comaNombrelargo" item-value="comaId" item-color="primary" label="Causa externa" clearable></v-autocomplete>
                                                    </v-col>
                                                </v-row>
                                                <transition-group name="list">
                                                  <!--:style="(seccionesvista.some(e=> e.hiseCodigo == seccion.hiseCodigo))?'display:flex':'display:none'"-->
                                                    <v-row v-for="seccion in  seccionesvista" :key="'row-'+seccion.hiseCodigo" :id="'CONTENEDOR_'+seccion.hiseCodigo" class="white mt-5"  >
                                                        <v-col cols="12" sm="12" md="12" class="primary--text">
                                                            <v-tooltip v-if="seccion.hiseDescripcion" max-width="300" color="light-blue darken-4" bottom>
                                                                <template v-slot:activator="{ on }">
                                                                    <h3 v-on="on">{{seccion.hiseNombre}}</h3>
                                                                </template>
                                                                <span>{{seccion.hiseDescripcion}}</span>
                                                            </v-tooltip>
                                                            <h3 v-else>{{seccion.hiseNombre}}
                                                                <template v-if="seccion.hiseHistorico">
                                                                  <v-btn color="success" @click="hiseIdHistorico=seccion.hiseCodigo;hiseNombreHistorico=seccion.hiseNombre;dialogsHistorico=true" text class="ml-3">Historico</v-btn>
                                                                </template>
                                                            </h3>
                                                        </v-col>
                                                        <v-col cols="12" sm="12" :md="c.hiseCol" v-for="c in  seccion.campos" :key="'col-'+c.hiseCodigo" :id="'CAMPO_'+c.hiseCodigo">
                                                            <div v-for="(item,index) in  formHistoria.filter( e=>e.hiseId == c.hiseId)" :key="'cam-'+item.hiseCodigo">
                                                                <input type="hidden" :id="'VALOR_'+c.hiseCodigo" :value="item.cihfValor" />
                                                                <div v-if="c.hiseRequerido">
                                                                    <v-tooltip v-if="c.hiseDescripcion" max-width="300" color="light-blue darken-4" right>
                                                                        <template v-slot:activator="{ on }">
                                                                            <label v-on="on" :for="c.hiseCodigo">{{c.hiseNombre}}</label>
                                                                        </template>
                                                                        <span>{{c.hiseDescripcion}}</span>
                                                                    </v-tooltip>
                                                                    <label v-else :for="c.hiseCodigo">{{c.hiseNombre}}</label>

                                                                    <template v-if="c.tipodato.comaNombrecorto == 'TEXT'">
                                                                        <v-text-field :id="c.hiseCodigo" dense outlined v-model="item.cihfValor" :error="$v.formHistoria.$each[index].cihfValor.$error" @change="ejecutarInput('VALOR_'+c.hiseCodigo,item.cihfValor)"></v-text-field>
                                                                    </template>
                                                                    <template v-else-if="c.tipodato.comaNombrecorto == 'TEXTAREA'">
                                                                        <v-textarea :id="c.hiseCodigo" :rows="c.hiseFilas" dense outlined v-model="item.cihfValor" :error="$v.formHistoria.$each[index].cihfValor.$error" @change="ejecutarInput('VALOR_'+c.hiseCodigo,item.cihfValor)"></v-textarea>
                                                                    </template>
                                                                    <template v-else-if="c.tipodato.comaNombrecorto == 'LISTA'">
                                                                        <v-autocomplete :id="c.hiseCodigo" dense outlined v-model="item.cihfValor" :error="$v.formHistoria.$each[index].cihfValor.$error" :items="getValues(c.hiseValores)" item-text="texts" item-value="values" item-color="primary" clearable @change="ejecutarInput('VALOR_'+c.hiseCodigo,item.cihfValor)"></v-autocomplete>
                                                                    </template>
                                                                    <template v-else-if="c.tipodato.comaNombrecorto == 'SI/NO'">
                                                                        <v-switch :id="c.hiseCodigo" dense outlined v-model="item.cihfValor" :error="$v.formHistoria.$each[index].cihfValor.$error" :true-value="c.hiseValores.split(';')[0]" :false-value="c.hiseValores.split(';')[1]" @change="ejecutarInput('VALOR_'+c.hiseCodigo,item.cihfValor)"></v-switch>
                                                                    </template>
                                                                    <template v-else-if="c.tipodato.comaNombrecorto == 'FECHA'">
                                                                        <v-menu v-model="item.menu" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="290px">
                                                                            <template v-slot:activator="{ on , attrs }">
                                                                                <v-text-field :id="c.hiseCodigo" required outlined dense  :error="$v.formHistoria.$each[index].cihfValor.$error" v-model="item.cihfValor" append-icon="event" readonly v-bind="attrs" v-on="on" clearable ></v-text-field>
                                                                            </template>
                                                                            <v-date-picker :id="'PIKER_'+c.hiseCodigo" locale="ES" v-model="item.cihfValor" @input="item.menu = false" @change="ejecutarInput('VALOR_'+c.hiseCodigo,item.cihfValor)" ></v-date-picker>
                                                                        </v-menu>
                                                                    </template>

                                                                </div>
                                                                <div v-else>
                                                                    <v-tooltip v-if="c.hiseDescripcion" max-width="300" color="light-blue darken-4" right>
                                                                        <template v-slot:activator="{ on }">
                                                                            <label v-on="on" :for="c.hiseCodigo">{{c.hiseNombre}}</label>
                                                                        </template>
                                                                        <span>{{c.hiseDescripcion}}</span>
                                                                    </v-tooltip>
                                                                    <label v-else :for="c.hiseCodigo">{{c.hiseNombre}}</label>
                                                                    <template v-if="c.tipodato.comaNombrecorto == 'TEXT'">
                                                                        <v-text-field :id="c.hiseCodigo" dense outlined v-model="item.cihfValor" @change="ejecutarInput('VALOR_'+c.hiseCodigo,item.cihfValor)"></v-text-field>
                                                                    </template>
                                                                    <template v-else-if="c.tipodato.comaNombrecorto == 'TEXTAREA'">
                                                                        <v-textarea :id="c.hiseCodigo" :rows="c.hiseFilas" dense outlined v-model="item.cihfValor" @change="ejecutarInput('VALOR_'+c.hiseCodigo,item.cihfValor)"></v-textarea>
                                                                    </template>
                                                                    <template v-else-if="c.tipodato.comaNombrecorto == 'LISTA'">
                                                                        <v-autocomplete :id="c.hiseCodigo" dense outlined v-model="item.cihfValor" :items="getValues(c.hiseValores)" item-text="texts" item-value="values" item-color="primary" @change="ejecutarInput('VALOR_'+c.hiseCodigo,item.cihfValor)" clearable></v-autocomplete>
                                                                    </template>
                                                                    <template v-else-if="c.tipodato.comaNombrecorto == 'SI/NO'">
                                                                        <v-switch :id="c.hiseCodigo" dense outlined v-model="item.cihfValor" :true-value="c.hiseValores.split(';')[0]" :false-value="c.hiseValores.split(';')[1]" @change="ejecutarInput('VALOR_'+c.hiseCodigo,item.cihfValor)"></v-switch>
                                                                    </template>
                                                                    <template v-else-if="c.tipodato.comaNombrecorto == 'FECHA'">
                                                                        <v-menu v-model="item.menu" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="290px">
                                                                            <template v-slot:activator="{ on , attrs }">
                                                                                <v-text-field :id="c.hiseCodigo" dense outlined v-model="item.cihfValor" append-icon="event" readonly v-bind="attrs" v-on="on"  clearable></v-text-field>
                                                                            </template>
                                                                            <v-date-picker :id="'PIKER_'+c.hiseCodigo" locale="ES" v-model="item.cihfValor" @input="item.menu = false" @change="ejecutarInput('VALOR_'+c.hiseCodigo,item.cihfValor)"></v-date-picker>
                                                                        </v-menu>
                                                                    </template>

                                                                </div>
                                                            </div>
                                                        </v-col>
                                                        <v-col md="12" v-if="seccion.hiseCodigo == 'V_HIGIEN_S5'">
                                                            <v-data-table :headers="odontogramaHeaders" :items-per-page="5" :items="odontograma" sort-desc sort-by="ciodId" class="elevation-1">
                                                                <template v-slot:top>
                                                                    <v-toolbar flat>
                                                                        <v-btn color="primary" @click="cargarConvenciones()" dark class="mb-2">Agregar convencion</v-btn>
                                                                    </v-toolbar>
                                                                </template>
                                                                <template v-slot:item[ciodConvencion]="{ item }">
                                                                    {{ convenciones.find( e => e.value == item.ciodConvencion) ? convenciones.find( e => e.value == item.ciodConvencion).text : '' }}
                                                                </template>
                                                                <template v-slot:[`item.actions`]="{ item }">
                                                                    <v-btn @click="quitarconvencion(item)" :loading="loadingDiente" outlined color="error" class="mx-2" small>
                                                                        <v-icon>mdi-delete</v-icon>
                                                                    </v-btn>

                                                                </template>
                                                            </v-data-table>
                                                        </v-col>
                                                        <v-col class="mt-0" md="12" v-if="seccion.hiseCodigo == 'V_HIGIEN_S5'">
                                                            <img src="" alt="" id="imgcanvasodontograma">
                                                            <div id="contenedorcanvasodontograma">
                                                                <canvas id="canvasodontograma" ref="canvasodontograma"></canvas>
                                                            </div>
                                                        </v-col>

                                                        <v-col md="12" v-if="seccion.hiseCodigo == 'V_HIGIEN_S11'">
                                                            <v-data-table :headers="placaHeaders" :items-per-page="5" :items="registroPlacaActual" sort-desc sort-by="ciodId" class="elevation-1">
                                                                <template v-slot:top>
                                                                    <v-toolbar flat>
                                                                        <v-btn color="primary" @click="sheetPlacas = true" dark class="mb-2">Agregar placa</v-btn>
                                                                    </v-toolbar>
                                                                </template>
                                                                <template v-slot:[`item.actions`]="{ item }">
                                                                    <v-btn @click="quitarplaca(item)" :loading="loadingDiente" outlined color="error" class="mx-2" small>
                                                                        <v-icon>mdi-delete</v-icon>
                                                                    </v-btn>
                                                                </template>
                                                            </v-data-table>
                                                        </v-col>
                                                        <v-col class="mt-0" md="12" v-if="seccion.hiseCodigo == 'V_HIGIEN_S11' ">
                                                            <img src="" alt="" id="imgcanvasplaca">
                                                            <div id="contenedorcanvasplaca">
                                                                <canvas id="canvasplaca" ref="canvasplaca"></canvas>
                                                            </div>
                                                        </v-col>
                                                    </v-row>
                                                </transition-group>
                                            </v-container>
                                            <v-container class="mb-12 grey lighten-3" v-if="loading2">
                                                <v-card :color="($vuetify.theme.dark)?'grey darken-4 mx-auto':'grey lighten-3 mx-auto'" width="180px" outlined dark>
                                                    <v-card-text class="text-center">
                                                        <span class="primary--text">Cargando datos </span>
                                                        <v-progress-circular indeterminate color="primary" class="mx-auto"></v-progress-circular>
                                                    </v-card-text>
                                                </v-card>
                                            </v-container>
                                        </v-stepper-content>
                                        <v-stepper-content step="2" v-if="!$route.params.triage">
                                            <v-divider></v-divider>
                                            <v-card-actions>
                                                <div class="flex-grow-1"></div>
                                                <v-btn color="red darken-1" text @click="cancelar">Cancelar</v-btn>
                                            </v-card-actions>
                                            <v-divider></v-divider>
                                            <v-container>
                                                <v-row class="">
                                                    <v-col cols="auto" md="12">
                                                        <v-data-table :headers="diagnosticosHeaders" :items-per-page="5" :items="listDiagnosticos" sort-desc sort-by="ciodId" class="elevation-1">
                                                            <template v-slot:top>
                                                                <v-toolbar flat>
                                                                    <v-toolbar-title>Diagnosticos</v-toolbar-title>
                                                                    <v-divider class="mx-4" inset vertical></v-divider>
                                                                    <v-spacer></v-spacer>
                                                                    <v-btn color="primary" @click="formDiagnosticos=listDiagnosticos;dialogDiagnosticos=true" dark class="mb-2 mr-2" v-if="listDiagnosticos.length  > 0">
                                                                        Editar
                                                                    </v-btn>
                                                                    <v-dialog v-model="dialogDiagnosticos" max-width="1000px">
                                                                        <template v-slot:activator="{ on, attrs }">
                                                                            <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on">
                                                                                Agregar
                                                                            </v-btn>
                                                                        </template>
                                                                        <v-card>
                                                                            <v-card-title>
                                                                                <span class="headline">Diagnosticos</span>
                                                                            </v-card-title>
                                                                            <v-card-text>
                                                                                <v-container>
                                                                                    <v-col cols="auto" md="12">
                                                                                        <v-row justify="space-around">
                                                                                            <v-col cols="auto" md="12">
                                                                                                <v-toolbar flat>
                                                                                                    <v-toolbar-title></v-toolbar-title>
                                                                                                    <v-btn color="primary" @click="agregarDiagnostico">Agregar nuevo</v-btn>
                                                                                                    <div class="flex-grow-1"></div>
                                                                                                </v-toolbar>
                                                                                            </v-col>
                                                                                        </v-row>
                                                                                    </v-col>
                                                                                    <v-col cols="12" sm="12" md="12">
                                                                                        <template v-for="(diagnostico,index) in formDiagnosticos">
                                                                                            <v-row :key="index" v-if="formDiagnosticos.length > 0">
                                                                                                <v-col cols="12" sm="12" md="12" v-if="diagnostico.tipocie10">
                                                                                                    <v-autocomplete dense outlined required :error="$v.formDiagnosticos.$each[index].cidiTipoCie10.$error" v-model="diagnostico.cidiTipoCie10" :loading="diagnostico.tipocie10.isLoading" :search-input.sync="diagnostico.tipocie10.search" hide-no-data hide-selected no-filter :items="diagnostico.tipocie10.tipocie10" @keypress.enter="searchCIE10(diagnostico.tipocie10)" item-text="comaNombrelargo" item-value="comaId" item-color="primary" label="Tipo de CIE 10" clearable>
                                                                                                        <template v-slot:selection="data">
                                                                                                            <v-list-item-content>
                                                                                                                <v-list-item-title class="text-wrap" v-html="data.item.comaNombrelargo"></v-list-item-title>
                                                                                                                <v-list-item-subtitle v-html="data.item.comaNombrecorto"></v-list-item-subtitle>
                                                                                                            </v-list-item-content>
                                                                                                        </template>
                                                                                                        <template v-slot:item="data">
                                                                                                            <template v-if="(typeof data.item !== 'object')">
                                                                                                                <v-list-item-content v-text="data.item"></v-list-item-content>
                                                                                                            </template>
                                                                                                            <template v-else>
                                                                                                                <v-list-item-content>
                                                                                                                    <v-list-item-title class="text-wrap" v-html="data.item.comaNombrelargo"></v-list-item-title>
                                                                                                                    <v-list-item-subtitle v-html="data.item.comaNombrecorto"></v-list-item-subtitle>
                                                                                                                </v-list-item-content>
                                                                                                            </template>
                                                                                                        </template>
                                                                                                    </v-autocomplete>
                                                                                                </v-col>
                                                                                                <v-col cols="12" sm="12" md="6">
                                                                                                    <v-autocomplete dense outlined required :error="$v.formDiagnosticos.$each[index].cidiTipoDiagnostico.$error" v-model="diagnostico.cidiTipoDiagnostico" :items="tipodiagnostico" item-text="comaNombrelargo" item-value="comaId" item-color="primary" label="Tipo de Diagnostico" clearable></v-autocomplete>
                                                                                                </v-col>
                                                                                                <v-col cols="12" sm="12" md="5">
                                                                                                    <v-autocomplete dense outlined required :error="$v.formDiagnosticos.$each[index].cidiTipoClase.$error" v-model="diagnostico.cidiTipoClase" :items="tipoclasedx" item-text="comaNombrelargo" item-value="comaId" item-color="primary" label="Tipo de Clase" clearable></v-autocomplete>
                                                                                                </v-col>
                                                                                                <v-col cols="auto" md="1" v-if="formDiagnosticos.length > 1">
                                                                                                    <v-btn text icon color="error" @click="eliminarDiagnosticoForm(diagnostico)">
                                                                                                        <v-icon>delete</v-icon>
                                                                                                    </v-btn>
                                                                                                </v-col>
                                                                                            </v-row>
                                                                                        </template>
                                                                                    </v-col>
                                                                                </v-container>
                                                                            </v-card-text>
                                                                            <v-card-actions>
                                                                                <v-spacer></v-spacer>
                                                                                <v-btn color="primary darken-1" text @click="formDiagnosticos=[];dialogDiagnosticos=false">Cancelar</v-btn>

                                                                                <v-btn color="primary darken-1" dark @click="agregarDiagnosticos()">Guardar</v-btn>

                                                                            </v-card-actions>
                                                                        </v-card>
                                                                    </v-dialog>
                                                                </v-toolbar>
                                                            </template>

                                                            <template v-slot:[`item.actions`]="{ item }">
                                                                <v-btn @click="eliminarDiagnostico(item)" outlined color="error" class="mx-2" small>
                                                                    <v-icon>mdi-delete</v-icon>
                                                                </v-btn>

                                                            </template>
                                                        </v-data-table>
                                                    </v-col>
                                                </v-row>
                                                <v-row class="">
                                                    <v-col cols="auto" md="12">
                                                        <v-data-table :headers="ProcesosRealizadosHeaders" :items-per-page="5" :items="listOrdenesrealizadas" sort-desc sort-by="ciorId" class="elevation-1">
                                                            <template v-slot:top>
                                                                <v-toolbar flat>
                                                                    <v-toolbar-title>Procesos realizados</v-toolbar-title>
                                                                    <v-divider class="mx-4" inset vertical></v-divider>
                                                                    <v-spacer></v-spacer>
                                                                    <v-dialog v-model="dialogPaqueteProcesosRealizados" max-width="1000px">
                                                                        <template v-slot:activator="{ on, attrs }">
                                                                            <v-btn color="primary" dark class="mb-2 mr-2" v-bind="attrs" v-on="on">
                                                                                Paquetes
                                                                            </v-btn>
                                                                        </template>
                                                                        <v-card>
                                                                            <v-card-title>
                                                                                <span class="headline">Paquetes</span>
                                                                            </v-card-title>
                                                                            <v-card-text>
                                                                                <v-container>
                                                                                    <v-col cols="12" sm="12" md="12">
                                                                                        <Paquetes :onEscojerPaquete="escojerPaquete" :tipo="'ProcesosRealizados'" />
                                                                                    </v-col>
                                                                                </v-container>
                                                                            </v-card-text>
                                                                            <v-card-actions>
                                                                                <v-spacer></v-spacer>
                                                                                <v-btn color="primary darken-1" text @click="dialogPaqueteProcesosRealizados=false">Cancelar</v-btn>
                                                                            </v-card-actions>
                                                                        </v-card>
                                                                    </v-dialog>
                                                                    <v-btn color="primary" @click="formOrdenesrealizadas=listOrdenesrealizadas;dialogProcesosRealizados=true" dark class="mb-2 mr-2" v-if="listOrdenesrealizadas.length  > 0">
                                                                        Editar
                                                                    </v-btn>
                                                                    <v-dialog v-model="dialogProcesosRealizados" max-width="1000px">
                                                                        <template v-slot:activator="{ on, attrs }">
                                                                            <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on">
                                                                                Agregar
                                                                            </v-btn>
                                                                        </template>
                                                                        <v-card>
                                                                            <v-card-title>
                                                                                <span class="headline">Procesos realizados</span>
                                                                            </v-card-title>
                                                                            <v-card-text>
                                                                                <v-container>
                                                                                    <v-col cols="auto" md="12">
                                                                                        <v-row justify="space-around">
                                                                                            <v-col cols="auto" md="12">
                                                                                                <v-toolbar flat>
                                                                                                    <v-toolbar-title></v-toolbar-title>
                                                                                                    <v-btn color="primary" @click="agregarOrdenrealizada">Agregar nuevo</v-btn>
                                                                                                    <div class="flex-grow-1"></div>
                                                                                                </v-toolbar>
                                                                                            </v-col>
                                                                                        </v-row>
                                                                                    </v-col>
                                                                                    <v-col cols="12" sm="12" md="12">
                                                                                        <template v-for="(items,index) in formOrdenesrealizadas">
                                                                                            <v-row :key="items.ciorId" v-if="formOrdenesrealizadas.length > 0">
                                                                                                <v-col cols="12" sm="12" md="6">
                                                                                                    <v-autocomplete dense outlined required :error="$v.formOrdenesrealizadas.$each[index].cocuId.$error" v-model="items.cocuId" :loading="items.cups.isLoading" :search-input.sync="items.cups.search" hide-no-data hide-selected no-filter :items="items.cups.cups" @keypress.enter="searchCup(items.cups)" item-text="cocuNombre" item-value="cocuId" item-color="primary" label="Cup" clearable></v-autocomplete>
                                                                                                </v-col>

                                                                                                <v-col cols="12" sm="12" md="4">
                                                                                                    <v-autocomplete dense outlined :error="$v.formOrdenesrealizadas.$each[index].ciorTipoOrden.$error" required v-model="items.ciorTipoOrden" :items="tipoordenes" item-text="comaNombrelargo" item-value="comaId" item-color="primary" label="Tipo de Orden" clearable></v-autocomplete>
                                                                                                </v-col>

                                                                                                <v-col cols="12" sm="12" md="2">
                                                                                                    <v-text-field dense outlined :error="$v.formOrdenesrealizadas.$each[index].ciorCantidad.$error" required label="Cantidad" v-model="items.ciorCantidad"></v-text-field>
                                                                                                </v-col>
                                                                                                <v-col cols="12" sm="12" md="10">
                                                                                                    <v-textarea dense outlined :error="$v.formOrdenesrealizadas.$each[index].ciorObservacion.$error" v-on:keyup="items.ciorObservacion = $event.target.value.toUpperCase()" required v-model="items.ciorObservacion" label="Observacion"></v-textarea>
                                                                                                </v-col>
                                                                                                <v-col cols="auto" md="1">
                                                                                                    <v-btn text icon color="error" @click="eliminarOrdenrealizadaForm(items)">
                                                                                                        <v-icon>delete</v-icon>
                                                                                                    </v-btn>
                                                                                                </v-col>
                                                                                            </v-row>
                                                                                        </template>
                                                                                    </v-col>
                                                                                </v-container>
                                                                            </v-card-text>
                                                                            <v-card-actions>
                                                                                <v-spacer></v-spacer>
                                                                                <v-btn color="primary darken-1" text @click="formOrdenesrealizadas=[];dialogProcesosRealizados=false">Cancelar</v-btn>

                                                                                <v-btn color="primary darken-1" dark @click="agregarOrdenesrealizadas()">Guardar</v-btn>

                                                                            </v-card-actions>
                                                                        </v-card>
                                                                    </v-dialog>
                                                                </v-toolbar>
                                                            </template>

                                                            <template v-slot:[`item.actions`]="{ item }">
                                                                <v-btn @click="eliminarOrdenrealizada(item)" outlined color="error" class="mx-2" small>
                                                                    <v-icon>mdi-delete</v-icon>
                                                                </v-btn>

                                                            </template>
                                                        </v-data-table>
                                                    </v-col>
                                                </v-row>
                                                <v-row class="">
                                                    <v-col cols="auto" md="12">
                                                        <v-data-table :headers="InsumosHeaders" :items-per-page="5" :items="listInsumos" sort-desc sort-by="insaId" class="elevation-1">
                                                            <template v-slot:top>
                                                                <v-toolbar flat>
                                                                    <v-toolbar-title>Insumos utilizados</v-toolbar-title>
                                                                    <v-divider class="mx-4" inset vertical></v-divider>
                                                                    <v-spacer></v-spacer>
                                                                    <v-btn color="primary" @click="formInsumos=listInsumos;dialogInsumos=true" dark class="mb-2 mr-2" v-if="listInsumos.length  > 0">
                                                                        Editar
                                                                    </v-btn>
                                                                    <v-dialog v-model="dialogInsumos" max-width="1000px">
                                                                        <template v-slot:activator="{ on, attrs }">
                                                                            <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on">
                                                                                Agregar
                                                                            </v-btn>
                                                                        </template>
                                                                        <v-card>
                                                                            <v-card-title>
                                                                                <span class="headline">Insumos utilizados</span>
                                                                            </v-card-title>
                                                                            <v-card-text>
                                                                                <v-container>
                                                                                    <v-col cols="auto" md="12">
                                                                                        <v-row justify="space-around">
                                                                                            <v-col cols="auto" md="12">
                                                                                                <v-toolbar flat>
                                                                                                    <v-toolbar-title></v-toolbar-title>
                                                                                                    <v-btn color="primary" @click="agregarInsumo">Agregar nuevo</v-btn>
                                                                                                    <div class="flex-grow-1"></div>
                                                                                                </v-toolbar>
                                                                                            </v-col>
                                                                                        </v-row>
                                                                                    </v-col>
                                                                                    <v-col cols="12" sm="12" md="12">
                                                                                        <template v-for="(items,index) in formInsumos">
                                                                                            <v-row :key="items.insaId" v-if="formInsumos.length > 0">
                                                                                                <v-col cols="12" sm="12" md="4">
                                                                                                    <v-text-field dense outlined required :error="$v.formInsumos.$each[index].insaCantidad.$error" v-model="items.insaCantidad" label="Cantidad"></v-text-field>
                                                                                                </v-col>
                                                                                                <v-col cols="12" sm="12" md="11">
                                                                                                    <v-autocomplete dense outlined required :error="$v.formInsumos.$each[index].coinId.$error" v-model="items.coinId" :items="insumos" item-text="coinNombre" item-value="coinId" item-color="primary" label="Insumo" clearable>
                                                                                                        <template v-slot:selection="data">
                                                                                                            <v-list-item-content>
                                                                                                                <v-list-item-title><span class="primary--text">{{data.item.coinNombre}}</span>
                                                                                                                </v-list-item-title>
                                                                                                                <v-list-item-title v-if="VERSION != 'semic'">STOCK: <strong> <span class="red--text" dark v-if="data.item.coinEstadostock== 'AGOTADO'">{{data.item.coinStock}} {{data.item.coinEstadostock}}</span>
                                                                                                                        <span class="yellow--text" dark v-else-if="data.item.coinEstadostock=='PRONTO A AGOTARCE'">{{data.item.coinStock}} {{data.item.coinEstadostock}}</span>
                                                                                                                        <span class="green--text" dark v-else>{{data.item.coinStock}} {{data.item.coinEstadostock}}</span></strong>
                                                                                                                </v-list-item-title>
                                                                                                            </v-list-item-content>
                                                                                                        </template>
                                                                                                        <template v-slot:item="data">
                                                                                                            <template v-if="(typeof data.item !== 'object')">
                                                                                                                <v-list-item-content v-text="data.item"></v-list-item-content>
                                                                                                            </template>
                                                                                                            <template v-else>
                                                                                                                <v-list-item-content>
                                                                                                                    <v-list-item-title><span class="primary--text">{{data.item.coinNombre}}</span>
                                                                                                                    </v-list-item-title>
                                                                                                                    <v-list-item-title v-if="VERSION != 'semic'">STOCK: <strong><span class="red--text" dark v-if="data.item.coinEstadostock== 'AGOTADO'">{{data.item.coinStock}} {{data.item.coinEstadostock}}</span>
                                                                                                                            <span class="yellow--text" dark v-else-if="data.item.coinEstadostock=='PRONTO A AGOTARCE'">{{data.item.coinStock}} {{data.item.coinEstadostock}}</span>
                                                                                                                            <span class="green--text" dark v-else>{{data.item.coinStock}} {{data.item.coinEstadostock}}</span></strong>
                                                                                                                    </v-list-item-title>
                                                                                                                </v-list-item-content>
                                                                                                            </template>
                                                                                                        </template>
                                                                                                    </v-autocomplete>
                                                                                                </v-col>
                                                                                                <v-col cols="auto" md="1">
                                                                                                    <v-btn text icon color="error" @click="eliminarInsumosForm(items)">
                                                                                                        <v-icon>delete</v-icon>
                                                                                                    </v-btn>
                                                                                                </v-col>
                                                                                            </v-row>
                                                                                        </template>
                                                                                    </v-col>
                                                                                </v-container>
                                                                            </v-card-text>
                                                                            <v-card-actions>
                                                                                <v-spacer></v-spacer>
                                                                                <v-btn color="primary darken-1" text @click="formInsumos=[];dialogInsumos=false">Cancelar</v-btn>
                                                                                <v-btn color="primary darken-1" dark @click="agregarInsumos()">Guardar</v-btn>
                                                                            </v-card-actions>
                                                                        </v-card>
                                                                    </v-dialog>
                                                                </v-toolbar>
                                                            </template>
                                                            <template v-slot:[`item.actions`]="{ item }">
                                                                <v-btn @click="eliminarInsumo(item)" outlined color="error" class="mx-2" small>
                                                                    <v-icon>mdi-delete</v-icon>
                                                                </v-btn>
                                                            </template>
                                                        </v-data-table>
                                                    </v-col>
                                                </v-row>
                                                <v-row class="">
                                                    <v-col cols="auto" md="12">
                                                        <v-data-table :headers="MediAplicadosHeaders" :items-per-page="5" :items="listMediAplicados" sort-desc sort-by="cireId" class="elevation-1">
                                                            <template v-slot:top>
                                                                <v-toolbar flat>
                                                                    <v-toolbar-title>Medicamentos aplicados</v-toolbar-title>
                                                                    <v-divider class="mx-4" inset vertical></v-divider>
                                                                    <v-spacer></v-spacer>
                                                                    <v-btn color="primary" @click="formMediAplicados=listMediAplicados;dialogMediAplicados=true" dark class="mb-2 mr-2" v-if="listMediAplicados.length  > 0">
                                                                        Editar
                                                                    </v-btn>
                                                                    <v-dialog v-model="dialogMediAplicados" max-width="1000px">
                                                                        <template v-slot:activator="{ on, attrs }">
                                                                            <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on">
                                                                                Agregar
                                                                            </v-btn>
                                                                        </template>
                                                                        <v-card>
                                                                            <v-card-title>
                                                                                <span class="headline">Medicamentos aplicados</span>
                                                                            </v-card-title>
                                                                            <v-card-text>
                                                                                <v-container>
                                                                                    <v-col cols="auto" md="12">
                                                                                        <v-row justify="space-around">
                                                                                            <v-col cols="auto" md="12">
                                                                                                <v-toolbar flat>
                                                                                                    <v-toolbar-title></v-toolbar-title>
                                                                                                    <v-btn color="primary" @click="agregarMediAplicado">Agregar nuevo</v-btn>
                                                                                                    <div class="flex-grow-1"></div>
                                                                                                </v-toolbar>
                                                                                            </v-col>
                                                                                        </v-row>
                                                                                    </v-col>
                                                                                    <v-col cols="12" sm="12" md="12">
                                                                                        <template v-for="(items,index) in formMediAplicados">
                                                                                            <v-row :key="items.cireId" v-if="formMediAplicados.length > 0">
                                                                                                <v-col cols="12" sm="12" md="4">
                                                                                                    <v-text-field dense outlined required :error="$v.formMediAplicados.$each[index].cimaCantidad.$error" v-model="items.cimaCantidad" label="Cantidad"></v-text-field>
                                                                                                </v-col>
                                                                                                <v-col cols="12" sm="12" md="11">
                                                                                                    <v-autocomplete dense outlined required v-model="items.mediId" :items="medicinas" item-text="mediNombre" item-value="mediId" item-color="primary" label="Medicamento" clearable>
                                                                                                        <template v-slot:selection="data">
                                                                                                            <v-list-item-content>
                                                                                                                <v-list-item-title><span class="primary--text">{{data.item.mediNombre}}</span>
                                                                                                                </v-list-item-title>
                                                                                                                <v-list-item-title v-if="VERSION != 'semic'">STOCK: <strong> <span class="red--text" dark v-if="data.item.mediEstadostock== 'AGOTADO'">{{data.item.mediStock}} {{data.item.mediEstadostock}}</span>
                                                                                                                        <span class="yellow--text" dark v-else-if="data.item.mediEstadostock=='PRONTO A AGOTARCE'">{{data.item.mediStock}} {{data.item.mediEstadostock}}</span>
                                                                                                                        <span class="green--text" dark v-else>{{data.item.mediStock}} {{data.item.mediEstadostock}}</span></strong>
                                                                                                                </v-list-item-title>
                                                                                                                <v-list-item-subtitle>
                                                                                                                    Via {{ data.item.via.comaNombrelargo}}
                                                                                                                </v-list-item-subtitle>
                                                                                                            </v-list-item-content>
                                                                                                        </template>
                                                                                                        <template v-slot:item="data">
                                                                                                            <template v-if="(typeof data.item !== 'object')">
                                                                                                                <v-list-item-content v-text="data.item"></v-list-item-content>
                                                                                                            </template>
                                                                                                            <template v-else>
                                                                                                                <v-list-item-content>
                                                                                                                    <v-list-item-title><span class="primary--text">{{data.item.mediNombre}}</span>
                                                                                                                    </v-list-item-title>
                                                                                                                    <v-list-item-title v-if="VERSION != 'semic'">STOCK: <strong><span class="red--text" dark v-if="data.item.mediEstadostock== 'AGOTADO'">{{data.item.mediStock}} {{data.item.mediEstadostock}}</span>
                                                                                                                            <span class="yellow--text" dark v-else-if="data.item.mediEstadostock=='PRONTO A AGOTARCE'">{{data.item.mediStock}} {{data.item.mediEstadostock}}</span>
                                                                                                                            <span class="green--text" dark v-else>{{data.item.mediStock}} {{data.item.mediEstadostock}}</span></strong>
                                                                                                                    </v-list-item-title>
                                                                                                                    <v-list-item-subtitle>
                                                                                                                        Via {{ data.item.via.comaNombrelargo}},
                                                                                                                        Concentracion {{ data.item.mediConcentracion}},
                                                                                                                        Forma farmaceutica {{ data.item.mediFormafarmaceutica}}
                                                                                                                        Presentacion {{data.item.presentacion.comaNombrelargo}} Unidad: {{data.item.unidad.comaNombrelargo}}
                                                                                                                    </v-list-item-subtitle>
                                                                                                                </v-list-item-content>
                                                                                                            </template>
                                                                                                        </template>
                                                                                                    </v-autocomplete>
                                                                                                </v-col>
                                                                                                <v-col cols="auto" md="1">
                                                                                                    <v-btn text icon color="error" @click="eliminarMediAplicadosForm(items)">
                                                                                                        <v-icon>delete</v-icon>
                                                                                                    </v-btn>
                                                                                                </v-col>
                                                                                                <v-col cols="12" sm="12" md="12">
                                                                                                    <v-textarea dense outlined required v-model="items.cimaDescripcion" label="Detalles de la aplicacion del medicamento"></v-textarea>
                                                                                                </v-col>
                                                                                            </v-row>
                                                                                        </template>
                                                                                    </v-col>
                                                                                </v-container>
                                                                            </v-card-text>
                                                                            <v-card-actions>
                                                                                <v-spacer></v-spacer>
                                                                                <v-btn color="primary darken-1" text @click="formMediAplicados=[];dialogMediAplicados=false">Cancelar</v-btn>
                                                                                <v-btn color="primary darken-1" dark @click="agregarMediAplicados()">Guardar</v-btn>
                                                                            </v-card-actions>
                                                                        </v-card>
                                                                    </v-dialog>
                                                                </v-toolbar>
                                                            </template>

                                                            <template v-slot:[`item.actions`]="{ item }">
                                                                <v-btn @click="eliminarMediAplicado(item)" outlined color="error" class="mx-2" small>
                                                                    <v-icon>mdi-delete</v-icon>
                                                                </v-btn>

                                                            </template>
                                                        </v-data-table>
                                                    </v-col>
                                                </v-row>
                                                <v-row class="">
                                                    <v-col cols="auto" md="12">
                                                        <v-data-table :headers="RecetariosHeaders" :items-per-page="5" :items="listRecetarios" sort-desc sort-by="cireId" class="elevation-1">
                                                            <template v-slot:top>
                                                                <v-toolbar flat>
                                                                    <v-toolbar-title>Recetarios</v-toolbar-title>
                                                                    <v-divider class="mx-4" inset vertical></v-divider>
                                                                    <v-spacer></v-spacer>
                                                                    <v-btn color="primary" @click="formRecetarios=listRecetarios;dialogRecetarios=true" dark class="mb-2 mr-2" v-if="listRecetarios.length  > 0">
                                                                        Editar
                                                                    </v-btn>
                                                                    <v-dialog v-model="dialogRecetarios" max-width="1000px">
                                                                        <template v-slot:activator="{ on, attrs }">
                                                                            <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on">
                                                                                Agregar
                                                                            </v-btn>
                                                                        </template>
                                                                        <v-card>
                                                                            <v-card-title>
                                                                                <span class="headline">Recetarios</span>
                                                                            </v-card-title>

                                                                            <v-card-text>
                                                                                <v-container>
                                                                                    <v-col cols="auto" md="12">
                                                                                        <v-row justify="space-around">
                                                                                            <v-col cols="auto" md="12">
                                                                                                <v-toolbar flat>
                                                                                                    <v-toolbar-title></v-toolbar-title>
                                                                                                    <v-btn color="primary" @click="agregarRecetario">Agregar nuevo</v-btn>
                                                                                                    <div class="flex-grow-1"></div>
                                                                                                </v-toolbar>
                                                                                            </v-col>
                                                                                        </v-row>
                                                                                    </v-col>
                                                                                    <v-col cols="12" sm="12" md="12">
                                                                                        <template v-for="(items,index) in formRecetarios">
                                                                                            <v-row :key="items.cireId" v-if="formRecetarios.length > 0">
                                                                                                <v-col cols="12" sm="12" md="12" v-if="items.medicamento">
                                                                                                    <v-row>
                                                                                                        <v-col cols="12" sm="12" md="8">
                                                                                                            <v-text-field dense outlined required label="Nombre" v-model="items.medicamento.mediNombre" v-on:keyup="$data.items.medicamento.mediNombre = $event.target.value.toUpperCase()"></v-text-field>
                                                                                                        </v-col>
                                                                                                        <v-col cols="12" sm="12" md="8">
                                                                                                            <v-text-field dense outlined required label="Forma farmaceutica" v-model="items.medicamento.mediFormafarmaceutica" v-on:keyup="$data.items.medicamento.mediFormafarmaceutica = $event.target.value.toUpperCase()"></v-text-field>
                                                                                                        </v-col>
                                                                                                        <v-col cols="12" sm="12" md="2">
                                                                                                            <v-text-field dense outlined required label="Concentracion" v-model="items.medicamento.mediConcentracion" v-on:keyup="$data.items.medicamento.mediConcentracion = $event.target.value.toUpperCase()"></v-text-field>
                                                                                                        </v-col>
                                                                                                        <v-col cols="12" sm="12" md="3">
                                                                                                            <v-autocomplete dense outlined required v-model="items.medicamento.mediTipoUnidad" :items="tipounidad" item-text="comaNombrelargo" item-value="comaId" item-color="primary" label="Tipo de unidad" clearable>
                                                                                                            </v-autocomplete>
                                                                                                        </v-col>
                                                                                                        <v-col cols="12" sm="12" md="3">
                                                                                                            <v-autocomplete dense outlined required v-model="items.medicamento.mediTipoVia" :items="tipovia" item-text="comaNombrelargo" item-value="comaId" item-color="primary" label="Tipo de Via" clearable>
                                                                                                            </v-autocomplete>
                                                                                                        </v-col>
                                                                                                        <v-col cols="12" sm="12" md="4">
                                                                                                            <v-autocomplete dense outlined required v-model="items.medicamento.mediTipoPresentacion" :items="tipopresentacion" item-text="comaNombrelargo" item-value="comaId" item-color="primary" label="Presentacion" clearable>
                                                                                                            </v-autocomplete>
                                                                                                        </v-col>
                                                                                                    </v-row>
                                                                                                </v-col>
                                                                                                <v-col cols="12" sm="12" md="4">
                                                                                                    <v-text-field dense outlined required :error="$v.formRecetarios.$each[index].cireFrecuenciahoras.$error" @keyup="calcularCantidad(items)" v-model="items.cireFrecuenciahoras" label="Frecuencia de horas" number></v-text-field>
                                                                                                </v-col>
                                                                                                <v-col cols="12" sm="12" md="4">
                                                                                                    <v-text-field dense outlined required :error="$v.formRecetarios.$each[index].cireDuraciondias.$error" @keyup="calcularCantidad(items)" v-model="items.cireDuraciondias" label="Duracion en dias" number></v-text-field>
                                                                                                </v-col>
                                                                                                <v-col cols="12" sm="12" md="4">
                                                                                                    <v-text-field dense outlined required :error="$v.formRecetarios.$each[index].cireCantidaddosis.$error" @keyup="calcularCantidad(items)" v-model="items.cireCantidaddosis" label="Cantidad de la dosis" number></v-text-field>
                                                                                                </v-col>
                                                                                                <v-col cols="12" sm="12" md="4">
                                                                                                    <v-text-field dense outlined required :error="$v.formRecetarios.$each[index].cireCantidadprescrita.$error" v-model="items.cireCantidadprescrita" label="Cantidad"></v-text-field>
                                                                                                </v-col>

                                                                                                <v-col cols="12" sm="12" md="11">
                                                                                                    <v-autocomplete dense outlined required v-if="!items.medicamento" v-model="items.mediId" :items="medicinas" item-text="mediNombre" item-value="mediId" item-color="primary" label="Medicamento" clearable>
                                                                                                        <template v-slot:selection="data">
                                                                                                            <v-list-item-content>
                                                                                                                <v-list-item-title><span class="primary--text">{{data.item.mediNombre}}</span>
                                                                                                                </v-list-item-title>
                                                                                                                <v-list-item-title v-if="VERSION != 'semic'">STOCK: <strong> <span class="red--text" dark v-if="data.item.mediEstadostock== 'AGOTADO'">{{data.item.mediStock}} {{data.item.mediEstadostock}}</span>
                                                                                                                        <span class="yellow--text" dark v-else-if="data.item.mediEstadostock=='PRONTO A AGOTARCE'">{{data.item.mediStock}} {{data.item.mediEstadostock}}</span>
                                                                                                                        <span class="green--text" dark v-else>{{data.item.mediStock}} {{data.item.mediEstadostock}}</span></strong>
                                                                                                                </v-list-item-title>
                                                                                                                <v-list-item-subtitle>
                                                                                                                    Via {{ data.item.via.comaNombrelargo}},
                                                                                                                    Concentracion {{ data.item.mediConcentracion}},
                                                                                                                    Forma farmaceutica {{ data.item.mediFormafarmaceutica}}
                                                                                                                    Presentacion {{data.item.presentacion.comaNombrelargo}} Unidad: {{data.item.unidad.comaNombrelargo}}
                                                                                                                </v-list-item-subtitle>
                                                                                                            </v-list-item-content>
                                                                                                        </template>
                                                                                                        <template v-slot:item="data">
                                                                                                            <template v-if="(typeof data.item !== 'object')">
                                                                                                                <v-list-item-content v-text="data.item"></v-list-item-content>
                                                                                                            </template>
                                                                                                            <template v-else>
                                                                                                                <v-list-item-content>
                                                                                                                    <v-list-item-title><span class="primary--text">{{data.item.mediNombre}}</span>
                                                                                                                    </v-list-item-title>
                                                                                                                    <v-list-item-title v-if="VERSION != 'semic'">STOCK: <strong><span class="red--text" dark v-if="data.item.mediEstadostock== 'AGOTADO'">{{data.item.mediStock}} {{data.item.mediEstadostock}}</span>
                                                                                                                            <span class="yellow--text" dark v-else-if="data.item.mediEstadostock=='PRONTO A AGOTARCE'">{{data.item.mediStock}} {{data.item.mediEstadostock}}</span>
                                                                                                                            <span class="green--text" dark v-else>{{data.item.mediStock}} {{data.item.mediEstadostock}}</span></strong>
                                                                                                                    </v-list-item-title>
                                                                                                                    <v-list-item-subtitle>
                                                                                                                        Via {{ data.item.via.comaNombrelargo}},
                                                                                                                        Concentracion {{ data.item.mediConcentracion}},
                                                                                                                        Forma farmaceutica {{ data.item.mediFormafarmaceutica}}
                                                                                                                        Presentacion {{data.item.presentacion.comaNombrelargo}} Unidad: {{data.item.unidad.comaNombrelargo}}
                                                                                                                    </v-list-item-subtitle>
                                                                                                                </v-list-item-content>
                                                                                                            </template>
                                                                                                        </template>
                                                                                                    </v-autocomplete>
                                                                                                </v-col>
                                                                                                <v-col cols="auto" md="1">
                                                                                                    <v-btn text icon v-if="!items.medicamento" color="success" @click="items.mediId= null;agregarMedicamento(items)">
                                                                                                        <v-icon>mdi-briefcase-plus-outline</v-icon>
                                                                                                    </v-btn>
                                                                                                    <v-btn text icon v-if="items.medicamento" color="error" @click="eliminarMedicamento(items)">
                                                                                                        <v-icon>mdi-briefcase-minus-outline</v-icon>
                                                                                                    </v-btn>
                                                                                                    <v-btn text icon color="error" @click="eliminarRecetarioForm(items)">
                                                                                                        <v-icon>delete</v-icon>
                                                                                                    </v-btn>
                                                                                                </v-col>
                                                                                                <v-col cols="12" sm="12" md="12">
                                                                                                    <v-textarea dense outlined required v-model="items.cireDetalles" label="Detalles de la formula"></v-textarea>
                                                                                                </v-col>
                                                                                            </v-row>
                                                                                        </template>
                                                                                    </v-col>
                                                                                </v-container>
                                                                            </v-card-text>
                                                                            <v-card-actions>
                                                                                <v-spacer></v-spacer>
                                                                                <v-btn color="primary darken-1" text @click="formRecetarios=[];dialogRecetarios=false">Cancelar</v-btn>

                                                                                <v-btn color="primary darken-1" dark @click="agregarRecetarios()">Guardar</v-btn>
                                                                            </v-card-actions>
                                                                        </v-card>
                                                                    </v-dialog>
                                                                </v-toolbar>
                                                            </template>

                                                            <template v-slot:[`item.actions`]="{ item }">
                                                                <v-btn @click="eliminarRecetario(item)" outlined color="error" class="mx-2" small>
                                                                    <v-icon>mdi-delete</v-icon>
                                                                </v-btn>

                                                            </template>
                                                        </v-data-table>
                                                    </v-col>
                                                </v-row>
                                                <v-row class="">
                                                    <v-col cols="auto" md="12">
                                                        <v-data-table :headers="OrdenesHeaders" :items-per-page="5" :items="listOrdenes" sort-desc sort-by="ciorId" class="elevation-1">
                                                            <template v-slot:top>
                                                                <v-toolbar flat>
                                                                    <v-toolbar-title>Ordenes</v-toolbar-title>
                                                                    <v-divider class="mx-4" inset vertical></v-divider>
                                                                    <v-spacer></v-spacer>
                                                                    <v-dialog v-model="dialogPaqueteOrdenes" max-width="1000px">
                                                                        <template v-slot:activator="{ on, attrs }">
                                                                            <v-btn color="primary" dark class="mb-2 mr-2" v-bind="attrs" v-on="on">
                                                                                Paquetes
                                                                            </v-btn>
                                                                        </template>
                                                                        <v-card>
                                                                            <v-card-title>
                                                                                <span class="headline">Paquetes</span>
                                                                            </v-card-title>
                                                                            <v-card-text>
                                                                                <v-container>
                                                                                    <v-col cols="12" sm="12" md="12">
                                                                                        <Paquetes :onEscojerPaquete="escojerPaquete" :tipo="'Ordenes'" />
                                                                                    </v-col>
                                                                                </v-container>
                                                                            </v-card-text>
                                                                            <v-card-actions>
                                                                                <v-spacer></v-spacer>
                                                                                <v-btn color="primary darken-1" text @click="dialogPaqueteOrdenes=false">Cancelar</v-btn>
                                                                            </v-card-actions>
                                                                        </v-card>
                                                                    </v-dialog>
                                                                    <v-btn color="primary" @click="formOrdenes=listOrdenes;dialogOrdenes=true" dark class="mb-2 mr-2" v-if="listOrdenes.length  > 0">
                                                                        Editar
                                                                    </v-btn>
                                                                    <v-dialog v-model="dialogOrdenes" max-width="1000px">
                                                                        <template v-slot:activator="{ on, attrs }">
                                                                            <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on">
                                                                                Agregar
                                                                            </v-btn>
                                                                        </template>
                                                                        <v-card>
                                                                            <v-card-title>
                                                                                <span class="headline">Ordenes</span>
                                                                            </v-card-title>

                                                                            <v-card-text>
                                                                                <v-container>
                                                                                    <v-col cols="auto" md="12">
                                                                                        <v-row justify="space-around">
                                                                                            <v-col cols="auto" md="12">
                                                                                                <v-toolbar flat>
                                                                                                    <v-toolbar-title></v-toolbar-title>
                                                                                                    <v-btn color="primary" @click="agregarOrden">Agregar nuevo</v-btn>
                                                                                                    <div class="flex-grow-1"></div>
                                                                                                </v-toolbar>
                                                                                            </v-col>
                                                                                        </v-row>
                                                                                    </v-col>
                                                                                    <v-col cols="12" sm="12" md="12">
                                                                                        <template v-for="(items,index) in formOrdenes">
                                                                                            <v-row :key="items.ciorId" v-if="formOrdenes.length > 0">
                                                                                                <v-col cols="12" sm="12" md="8">
                                                                                                    <v-autocomplete dense outlined required :error="$v.formOrdenes.$each[index].cocuId.$error" v-model="items.cocuId" :loading="items.cups.isLoading" :search-input.sync="items.cups.search" hide-no-data hide-selected no-filter :items="items.cups.cups" @keypress.enter="searchCup(items.cups)" item-text="cocuNombre" item-value="cocuId" item-color="primary" label="Cup" clearable></v-autocomplete>
                                                                                                </v-col>
                                                                                                <v-col cols="12" sm="12" md="2">
                                                                                                    <v-autocomplete dense outlined :error="$v.formOrdenes.$each[index].ciorTipoOrden.$error" required v-model="items.ciorTipoOrden" :items="tipoordenes" item-text="comaNombrelargo" item-value="comaId" item-color="primary" label="Tipo de Orden" clearable></v-autocomplete>
                                                                                                </v-col>
                                                                                                <v-col cols="12" sm="12" md="2">
                                                                                                    <v-text-field dense outlined :error="$v.formOrdenes.$each[index].ciorCantidad.$error" label="Cantidad" v-model="items.ciorCantidad"></v-text-field>
                                                                                                </v-col>
                                                                                                <v-col cols="12" sm="12" md="10">
                                                                                                    <v-textarea dense outlined  v-on:keyup="items.ciorObservacion = $event.target.value.toUpperCase()" v-model="items.ciorObservacion" label="Observacion"></v-textarea>
                                                                                                </v-col>

                                                                                                <v-col cols="auto" md="1">
                                                                                                    <v-btn text icon color="error" @click="eliminarOrdenForm(items)">
                                                                                                        <v-icon>delete</v-icon>
                                                                                                    </v-btn>
                                                                                                </v-col>
                                                                                            </v-row>
                                                                                        </template>
                                                                                    </v-col>
                                                                                </v-container>
                                                                            </v-card-text>
                                                                            <v-card-actions>
                                                                                <v-spacer></v-spacer>
                                                                                <v-btn color="primary darken-1" text @click="formOrdenes=[];dialogOrdenes=false">Cancelar</v-btn>

                                                                                <v-btn color="primary darken-1" dark @click="agregarOrdenes()">Guardar</v-btn>
                                                                            </v-card-actions>
                                                                        </v-card>
                                                                    </v-dialog>
                                                                </v-toolbar>
                                                            </template>

                                                            <template v-slot:[`item.actions`]="{ item }">
                                                                <v-btn @click="eliminarOrden(item)" outlined color="error" class="mx-2" small>
                                                                    <v-icon>mdi-delete</v-icon>
                                                                </v-btn>

                                                            </template>
                                                        </v-data-table>
                                                    </v-col>
                                                </v-row>
                                                <v-row class="">
                                                    <v-col cols="auto" md="12">
                                                        <v-data-table :headers="RemisionesHeaders" :items-per-page="5" :items="listRemisiones" sort-desc sort-by="cirmId" class="elevation-1">
                                                            <template v-slot:top>
                                                                <v-toolbar flat>
                                                                    <v-toolbar-title>Remisiones</v-toolbar-title>
                                                                    <v-divider class="mx-4" inset vertical></v-divider>
                                                                    <v-spacer></v-spacer>
                                                                    <v-btn color="primary" @click="formRemisiones=listRemisiones;dialogRemisiones=true" dark class="mb-2 mr-2" v-if="listRemisiones.length  > 0">
                                                                        Editar
                                                                    </v-btn>
                                                                    <v-dialog v-model="dialogRemisiones" max-width="1000px">
                                                                        <template v-slot:activator="{ on, attrs }">
                                                                            <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on">
                                                                                Agregar
                                                                            </v-btn>
                                                                        </template>
                                                                        <v-card>
                                                                            <v-card-title>
                                                                                <span class="headline">Remisiones</span>
                                                                            </v-card-title>
                                                                            <v-card-text>
                                                                                <v-container>
                                                                                    <v-col cols="auto" md="12">
                                                                                        <v-row justify="space-around">
                                                                                            <v-col cols="auto" md="12">
                                                                                                <v-toolbar flat>
                                                                                                    <v-toolbar-title></v-toolbar-title>
                                                                                                    <v-btn color="primary" @click="agregarRemision">Agregar nuevo</v-btn>
                                                                                                    <div class="flex-grow-1"></div>
                                                                                                </v-toolbar>
                                                                                            </v-col>
                                                                                        </v-row>
                                                                                    </v-col>
                                                                                    <v-col cols="12" sm="12" md="12">
                                                                                        <template v-for="(items,index) in formRemisiones">
                                                                                            <v-row :key="items.cirmId" v-if="formRemisiones.length > 0">
                                                                                                <v-col cols="12" sm="12" md="12">
                                                                                                    <v-autocomplete dense outlined :error="$v.formRemisiones.$each[index].cocuId.$error" required v-model="items.cocuId" :loading="items.cups.isLoading" :search-input.sync="items.cups.search" hide-no-data hide-selected no-filter :items="items.cups.cups" @keypress.enter="searchCup(items.cups)" item-text="cocuNombre" item-value="cocuId" item-color="primary" label="Servicio" clearable></v-autocomplete>
                                                                                                </v-col>
                                                                                                <v-col cols="12" sm="12" md="10">
                                                                                                    <v-textarea dense outlined :error="$v.formRemisiones.$each[index].cirmObservacion.$error" required v-model="items.cirmObservacion" label="Observacion"></v-textarea>
                                                                                                </v-col>
                                                                                                <v-col cols="auto" md="1">
                                                                                                    <v-btn text icon color="error" @click="eliminarRemisionForm(items)">
                                                                                                        <v-icon>delete</v-icon>
                                                                                                    </v-btn>
                                                                                                </v-col>
                                                                                            </v-row>
                                                                                        </template>
                                                                                    </v-col>
                                                                                </v-container>
                                                                            </v-card-text>
                                                                            <v-card-actions>
                                                                                <v-spacer></v-spacer>
                                                                                <v-btn color="primary darken-1" text @click="formRemisiones=[];dialogRemisiones=false">Cancelar</v-btn>

                                                                                <v-btn color="primary darken-1" dark @click="agregarRemisiones()">Guardar</v-btn>
                                                                            </v-card-actions>
                                                                        </v-card>
                                                                    </v-dialog>
                                                                </v-toolbar>
                                                            </template>
                                                            <template v-slot:[`item.actions`]="{ item }">
                                                                <v-btn @click="eliminarRemision(item)" outlined color="error" class="mx-2" small>
                                                                    <v-icon>mdi-delete</v-icon>
                                                                </v-btn>
                                                            </template>
                                                        </v-data-table>
                                                    </v-col>
                                                </v-row>
                                                <v-row class="">
                                                    <v-col cols="auto" md="12">
                                                        <v-data-table :headers="IncapacidadesHeaders" :items-per-page="5" :items="listIncapacidades" sort-desc sort-by="ciceId" class="elevation-1">
                                                            <template v-slot:top>
                                                                <v-toolbar flat>
                                                                    <v-toolbar-title>Incapacidades</v-toolbar-title>
                                                                    <v-divider class="mx-4" inset vertical></v-divider>
                                                                    <v-spacer></v-spacer>
                                                                    <v-btn color="primary" @click="formIncapacidades=listIncapacidades;dialogIncapacidades=true" dark class="mb-2 mr-2" v-if="listIncapacidades.length  > 0">
                                                                        Editar
                                                                    </v-btn>
                                                                    <v-dialog v-model="dialogIncapacidades" max-width="1000px">
                                                                        <template v-slot:activator="{ on, attrs }">
                                                                            <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on">
                                                                                Agregar
                                                                            </v-btn>
                                                                        </template>
                                                                        <v-card>
                                                                            <v-card-title>
                                                                                <span class="headline">Incapacidades</span>
                                                                            </v-card-title>
                                                                            <v-card-text>
                                                                                <v-container>
                                                                                    <v-col cols="auto" md="12">
                                                                                        <v-row justify="space-around">
                                                                                            <v-col cols="auto" md="12">
                                                                                                <v-toolbar flat>
                                                                                                    <v-toolbar-title></v-toolbar-title>
                                                                                                    <v-btn color="primary" @click="agregarIncapacidadCertificado('INCAPACIDAD')">Agregar nuevo</v-btn>
                                                                                                    <div class="flex-grow-1"></div>
                                                                                                </v-toolbar>
                                                                                            </v-col>
                                                                                        </v-row>
                                                                                    </v-col>
                                                                                    <v-col cols="12" sm="12" md="12">
                                                                                        <template v-for="(items,index) in formIncapacidades">
                                                                                            <v-row :key="'inca-'+index">
                                                                                                <v-col cols="12" sm="12" md="12">
                                                                                                    <v-btn color="danger" @click="eliminarIncapacidadCertificadoForm('INCAPACIDAD',items)">Eliminar</v-btn>
                                                                                                </v-col>
                                                                                                <v-col cols="12" sm="12" md="6">
                                                                                                    <v-menu v-model="items.menu" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="290px">
                                                                                                        <template v-slot:activator="{ on }">
                                                                                                            <v-text-field required dense outlined :error="$v.formIncapacidades.$each[index].ciceFechainicio.$error" v-model="items.ciceFechainicio" label="Fecha inicio" append-icon="event" readonly v-on="on"></v-text-field>
                                                                                                        </template>
                                                                                                        <v-date-picker locale="ES" v-model="items.ciceFechainicio" @input="items.menu = false" @change="cambiarFecha(items)"></v-date-picker>
                                                                                                    </v-menu>
                                                                                                </v-col>
                                                                                                <v-col cols="12" sm="12" md="6">
                                                                                                    <v-text-field required dense outlined :error="$v.formIncapacidades.$each[index].ciceFechafinal.$error" v-model="items.ciceFechafinal" label="Fecha final" append-icon="event" readonly></v-text-field>
                                                                                                </v-col>
                                                                                                <v-col cols="12" sm="12" md="6">
                                                                                                    <v-text-field @input="cambiarFecha(items)" outlined dense :error="$v.formIncapacidades.$each[index].ciceDias.$error" v-model="items.ciceDias" label="Dias"></v-text-field>
                                                                                                </v-col>
                                                                                            </v-row>
                                                                                        </template>
                                                                                    </v-col>
                                                                                </v-container>
                                                                            </v-card-text>
                                                                            <v-card-actions>
                                                                                <v-spacer></v-spacer>
                                                                                <v-btn color="primary darken-1" text @click="formIncapacidades=[];dialogIncapacidades=false">Cancelar</v-btn>

                                                                                <v-btn color="primary darken-1" dark @click="agregarIncapacidades()"> Guardar</v-btn>
                                                                            </v-card-actions>
                                                                        </v-card>
                                                                    </v-dialog>
                                                                </v-toolbar>
                                                            </template>
                                                            <template v-slot:[`item.actions`]="{ item }">
                                                                <v-btn @click="eliminarIncapacidadCertificado('INCAPACIDAD',item)" outlined color="error" class="mx-2" small>
                                                                    <v-icon>mdi-delete</v-icon>
                                                                </v-btn>
                                                            </template>
                                                        </v-data-table>
                                                    </v-col>
                                                </v-row>
                                                <v-row class="">
                                                    <v-col cols="auto" md="12">
                                                        <v-data-table :headers="CertificadosHeaders" :items-per-page="5" :items="listCertificados" sort-desc sort-by="ciceId" class="elevation-1">
                                                            <template v-slot:top>
                                                                <v-toolbar flat>
                                                                    <v-toolbar-title>Certificados</v-toolbar-title>
                                                                    <v-divider class="mx-4" inset vertical></v-divider>
                                                                    <v-spacer></v-spacer>
                                                                    <v-btn color="primary" @click="formCertificados=listCertificados;dialogCertificados=true" dark class="mb-2 mr-2" v-if="listCertificados.length  > 0">
                                                                        Editar
                                                                    </v-btn>
                                                                    <v-dialog v-model="dialogCertificados" max-width="1000px">
                                                                        <template v-slot:activator="{ on, attrs }">
                                                                            <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on">
                                                                                Agregar
                                                                            </v-btn>
                                                                        </template>
                                                                        <v-card>
                                                                            <v-card-title>
                                                                                <span class="headline">Certificados</span>
                                                                            </v-card-title>
                                                                            <v-card-text>
                                                                                <v-container>
                                                                                    <v-col cols="auto" md="12">
                                                                                        <v-row justify="space-around">
                                                                                            <v-col cols="auto" md="12">
                                                                                                <v-toolbar flat>
                                                                                                    <v-toolbar-title></v-toolbar-title>
                                                                                                    <v-btn color="primary" @click="agregarIncapacidadCertificado('CERTIFICADO')">Agregar nuevo</v-btn>
                                                                                                    <div class="flex-grow-1"></div>
                                                                                                </v-toolbar>
                                                                                            </v-col>
                                                                                        </v-row>
                                                                                    </v-col>
                                                                                    <v-col cols="12" sm="12" md="12">
                                                                                        <template v-for="(items,index) in formCertificados">
                                                                                            <v-row :key="'cert-'+index">
                                                                                                <v-col cols="12" sm="12" md="12">
                                                                                                    <v-btn color="danger" @click="eliminarIncapacidadCertificadoForm('CERTIFICADO',items)">Eliminar</v-btn>
                                                                                                </v-col>
                                                                                                <v-col cols="12" sm="12" md="12">
                                                                                                    <v-textarea outlined dense :error="$v.formCertificados.$each[index].ciceCertificado.$error" required v-model="items.ciceCertificado" label="Certificado"></v-textarea>
                                                                                                </v-col>
                                                                                            </v-row>
                                                                                        </template>
                                                                                    </v-col>
                                                                                </v-container>
                                                                            </v-card-text>
                                                                            <v-card-actions>
                                                                                <v-spacer></v-spacer>
                                                                                <v-btn color="primary darken-1" text @click="formCertificados=[];dialogCertificados=false">Cancelar</v-btn>

                                                                                <v-btn color="primary darken-1" dark @click="agregarCertificados()"> Guardar</v-btn>
                                                                            </v-card-actions>
                                                                        </v-card>
                                                                    </v-dialog>
                                                                </v-toolbar>
                                                            </template>
                                                            <template v-slot:[`item.actions`]="{ item }">
                                                                <v-btn @click="eliminarIncapacidadCertificado('CERTIFICADO',item)" outlined color="error" class="mx-2" small>
                                                                    <v-icon>mdi-delete</v-icon>
                                                                </v-btn>
                                                            </template>
                                                        </v-data-table>
                                                    </v-col>
                                                </v-row>
                                            </v-container>
                                            <v-divider></v-divider>
                                            <v-card-actions>
                                                <div class="flex-grow-1"></div>
                                                <v-btn color="primary darken-1" dark @click="finalizar()">Finalizar</v-btn>
                                            </v-card-actions>
                                            <v-divider></v-divider>
                                        </v-stepper-content>
                                    </v-stepper-items>
                                </v-stepper>
                            </v-form>
                        </v-col>
                    </v-row>
                    <v-dialog v-model="dialog" width="800px" min-width="800px">
                        <v-card>
                            <v-card-title>
                                <span class="headline">REGISTRO DE CONTROL DE PLACA</span>
                            </v-card-title>
                            <v-divider></v-divider>
                            <v-card-text>
                                <v-container>
                                    <v-row>
                                        <v-col cols="12" sm="12" md="12">
                                            <!-- <canvas width="1200" height="500" id="canvasplaca" ref="canvasplaca"></canvas> -->
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </v-card-text>
                            <v-divider></v-divider>
                            <v-card-actions>
                                <div class="flex-grow-1"></div>
                                <v-btn color="primary darken-1" text @click="dialog = false">Cerrar</v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                    <v-dialog v-model="dialogsHistorico" width="1200px" min-width="1200px">
                        <v-card>
                            <v-card-title>
                                <span class="headline">Historico de {{ hiseNombreHistorico }}</span>
                            </v-card-title>
                            <v-divider></v-divider>
                            <v-card-text>
                                <v-container>
                                    <v-row>
                                        <v-col cols="12" sm="12" md="12" v-if="paciente && hiseIdHistorico">
                                            <HistoricoSeccion :coppId="paciente.coppId" v-bind:hiseId="hiseIdHistorico" />
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </v-card-text>
                            <v-divider></v-divider>
                            <v-card-actions>
                                <div class="flex-grow-1"></div>
                                <v-btn color="primary darken-1" text @click="dialogsHistorico = false;hiseIdHistorico = null">Cerrar</v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                </v-container>
            </v-tab-item>
            <v-tab-item value="tab-2" v-if="paciente" class="bg-tab">
                <Historialresultados :coppId="paciente.coppId" />
            </v-tab-item>
            <v-tab-item value="tab-3" v-if="paciente" class="bg-tab">
                <Historialprocedimientos :coppId="paciente.coppId" />
            </v-tab-item>
            <v-tab-item value="tab-4" v-if="factura">
                <Graficas :factura="factura" />
            </v-tab-item>
            <v-tab-item value="tab-5" v-if="paciente">
                <HistoricoPaciente :coppId="paciente.coppId" :coseId="factura.tblCitaCita.tblConfContratosxservicios[0].coseId" />
            </v-tab-item>
            <template v-if="habanexo">
              <v-tab-item value="tab-6" v-if="paciente">
                  <Anexos :copeIdentificacion="paciente.tblConfPersonasnaturale.tblConfPersona.copeIdentificacion" :copeId="paciente.tblConfPersonasnaturale.copeId" :cifaId="factura.cifaId"  />
              </v-tab-item>
              <v-tab-item value="tab-7" v-if="paciente">
                  <Anexos :copeIdentificacion="paciente.tblConfPersonasnaturale.tblConfPersona.copeIdentificacion" :copeId="paciente.tblConfPersonasnaturale.copeId"  />
              </v-tab-item>
            </template>
            <v-tab-item :value="habanexo? 'tab-8':'tab-6'" v-if="paciente">
                <Demanda :coppId="paciente.tblConfPersonasnaturale.tblConfPersona.copeId" />
            </v-tab-item>
        </v-tabs-items>
        <div class="text-center">
            <v-dialog v-model="sheetConvenciones" transition="dialog-bottom-transition" scrollable width="1200px" min-width="1200px">
                <v-card tile>
                    <v-toolbar flat dark color="primary">
                        <v-btn icon dark @click="sheetConvenciones = !sheetConvenciones">
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                        <v-toolbar-title>Convenciones</v-toolbar-title>
                    </v-toolbar>
                    <v-card-text>
                        <v-container>
                            <v-row class="white">
                                <v-col cols="auto" md="12">
                                    <v-row justify="space-around">
                                        <v-col cols="auto" md="12">
                                            <v-toolbar flat>
                                                <v-btn text @click="agregarDiente">Agregar diente</v-btn>

                                                <div class="flex-grow-1"></div>
                                            </v-toolbar>
                                        </v-col>
                                    </v-row>
                                </v-col>
                                <v-col cols="12" sm="12" md="12">
                                    <template v-for="(diente,index) in formOdontograma">
                                        <v-row :key="index" v-if="formOdontograma.length > 0">

                                            <v-col cols="12" sm="12" md="2">
                                                <label>Diente</label>
                                                <v-autocomplete dense outlined :error="$v.formOdontograma.$each[index].ciodDiente.$error" v-model="diente.ciodDiente" :items="dientesodontograma" item-text="numero" item-value="numero" item-color="primary" clearable></v-autocomplete>
                                            </v-col>
                                            <v-col cols="12" sm="12" md="4">
                                                <label>Parte</label>
                                                <v-autocomplete dense outlined v-model="diente.ciodParte" :items="(dientesodontograma.find(e => e.numero == diente.ciodDiente ))?dientesodontograma.find(e => e.numero == diente.ciodDiente ).partesArray:[]" item-text="name" item-value="id" item-color="primary" clearable>
                                                    <template v-slot:selection="data">
                                                        <v-list-item-avatar>
                                                            <img :src="partes.find(e => e.text == data.item.name).img">
                                                        </v-list-item-avatar>
                                                        <v-list-item-content>
                                                            <v-list-item-title class="text-wrap" v-html="data.item.name"></v-list-item-title>
                                                        </v-list-item-content>
                                                    </template>

                                                    <template v-slot:item="data">
                                                        <template v-if="(typeof data.item !== 'object')">
                                                            <v-list-item-content v-text="data.item"></v-list-item-content>
                                                        </template>
                                                        <template v-else>
                                                            <v-list-item-avatar>
                                                                <img :src="partes.find(e => e.text == data.item.name).img">
                                                            </v-list-item-avatar>
                                                            <v-list-item-content>
                                                                <v-list-item-title class="" v-html="data.item.name"></v-list-item-title>
                                                            </v-list-item-content>
                                                        </template>
                                                    </template>
                                                </v-autocomplete>
                                            </v-col>
                                            <v-col cols="12" sm="4" md="6">
                                                <label>Convencion</label>
                                                <v-autocomplete dense outlined :error="$v.formOdontograma.$each[index].ciodConvencion.$error" v-model="diente.ciodConvencion" :items="convenciones.filter( a => a.partes.find( e => e == ((diente.ciodParte != null)?diente.ciodParte.split('-')[0]:'diente')) != undefined)" item-text="text" item-value="value" item-color="primary" clearable>
                                                    <template v-slot:selection="data">
                                                        <v-list-item-avatar>
                                                            <img :src="data.item.img">
                                                        </v-list-item-avatar>
                                                        <v-list-item-content>
                                                            <v-list-item-title class="text-wrap" v-html="data.item.text"></v-list-item-title>
                                                        </v-list-item-content>
                                                    </template>

                                                    <template v-slot:item="data">
                                                        <template v-if="(typeof data.item !== 'object')">
                                                            <v-list-item-content v-text="data.item"></v-list-item-content>
                                                        </template>
                                                        <template v-else>
                                                            <v-list-item-avatar>
                                                                <img :src="data.item.img">
                                                            </v-list-item-avatar>
                                                            <v-list-item-content>
                                                                <v-list-item-title class="" v-html="data.item.text"></v-list-item-title>
                                                            </v-list-item-content>
                                                        </template>
                                                    </template>
                                                </v-autocomplete>
                                            </v-col>
                                            <v-col cols="auto" md="1" v-if="formOdontograma.length > 1">
                                                <v-btn text icon color="error" @click="eliminarDiente(diente)">
                                                    <v-icon>delete</v-icon>
                                                </v-btn>
                                            </v-col>
                                        </v-row>
                                    </template>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card-actions>
                        <v-btn color="primary darken-1" :loading="loadingDiente" @click="colocarconvencion()">Agregar</v-btn>
                        <div class="flex-grow-1"></div>
                        <v-btn color="primary darken-1" text @click="sheetConvenciones = false">Cerrar</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <v-dialog v-model="sheetPlacas" transition="dialog-bottom-transition" scrollable width="800px" min-width="800px">
                <v-card tile>
                    <v-toolbar flat dark color="primary">
                        <v-btn icon dark @click="sheetPlacas = !sheetPlacas">
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                        <v-toolbar-title>Colocar placa</v-toolbar-title>
                    </v-toolbar>
                    <v-card-text>
                        <v-container>
                            <v-row class="white">
                                <v-col cols="auto" md="12">
                                    <v-row justify="space-around">
                                        <v-col cols="auto" md="12">
                                            <v-toolbar flat>
                                                <v-btn text @click="agregarDientePlaca">Agregar diente</v-btn>
                                                <div class="flex-grow-1"></div>
                                            </v-toolbar>
                                        </v-col>
                                    </v-row>
                                </v-col>
                                <v-col cols="12" sm="12" md="12">
                                    <template v-for="(diente,index) in formPlaca">
                                        <v-row :key="index" v-if="formPlaca.length > 0">
                                            <v-col cols="12" sm="12" md="4">
                                                <label>Diente</label>
                                                <v-autocomplete dense outlined :error="$v.formPlaca.$each[index].ciodDiente.$error" v-model="diente.ciodDiente" :items="dientesplaca" item-text="numero" item-value="numero" item-color="primary" clearable></v-autocomplete>
                                            </v-col>
                                            <v-col cols="12" sm="12" md="8">
                                                <label>Parte</label>
                                                <v-autocomplete dense outlined :error="$v.formPlaca.$each[index].ciodParte.$error" v-model="diente.ciodParte" :items="(dientesplaca.find(e => e.numero == diente.ciodDiente ))?dientesplaca.find(e => e.numero == diente.ciodDiente ).partesArray:[]" item-text="name" item-value="id" item-color="primary" clearable>
                                                    <template v-slot:selection="data">
                                                        <v-list-item-avatar>
                                                            <img :src="partes.find(e => e.text == data.item.name).img">
                                                        </v-list-item-avatar>
                                                        <v-list-item-content>
                                                            <v-list-item-title class="text-wrap" v-html="data.item.name"></v-list-item-title>
                                                        </v-list-item-content>
                                                    </template>
                                                    <template v-slot:item="data">
                                                        <template v-if="(typeof data.item !== 'object')">
                                                            <v-list-item-content v-text="data.item"></v-list-item-content>
                                                        </template>
                                                        <template v-else>
                                                            <v-list-item-avatar>
                                                                <img :src="partes.find(e => e.text == data.item.name).img">
                                                            </v-list-item-avatar>
                                                            <v-list-item-content>
                                                                <v-list-item-title class="" v-html="data.item.name"></v-list-item-title>
                                                            </v-list-item-content>
                                                        </template>
                                                    </template>
                                                </v-autocomplete>
                                            </v-col>
                                            <v-col cols="auto" md="1" v-if="formPlaca.length > 1">
                                                <v-btn text icon color="error" @click="eliminarDientePlaca(diente)">
                                                    <v-icon>delete</v-icon>
                                                </v-btn>
                                            </v-col>
                                        </v-row>
                                    </template>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card-actions>
                        <v-btn color="primary darken-1" :loading="loadingDiente" @click="colocarplaca()">Agregar</v-btn>
                        <div class="flex-grow-1"></div>
                        <v-btn color="primary darken-1" text @click="sheetPlacas = false">Cerrar</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </div>
        <div id="scripthistoria"></div>
    </div>
</template>

<script>
import axios from 'axios';
import postscribe from 'postscribe';
import Historialresultados from "./Historialresultados";
import Historialprocedimientos from "./Historialprocedimientos";
import Paquetes from "../../components/Paquetes";
import HistoricoSeccion from "../../components/HistoricoSeccion";
import HistoricoPaciente from "../../components/HistoricoPaciente";
import Anexos from "../../components/Anexos";
import Demanda from "../demanda/Demanda.vue";
import {
  required
}
from 'vuelidate/lib/validators';
import Graficas from './Graficas.vue';
import generales from '../../mixins/generales.js';
import odontologia from '../../mixins/odontologia.js';
export default {
  mixins: [generales, odontologia],
  components: {
    Historialresultados,
    Historialprocedimientos,
    Paquetes,
    HistoricoSeccion,
    Graficas,
    HistoricoPaciente,
    Anexos,
    Demanda
  },
  data() {
    return {
      e1: 1,
      pag: 1,
      tab: 'tab-1',
      dialog: false,
      hiseIdHistorico: null,
      hiseNombreHistorico: '',
      dialogsHistorico: false,
      insertando: false,
      VERSION: this.$cookies.get("VERSION"),
      menu: false,
      nuevo: false,
      factura: null,
      paciente: null,
      registroPlacaAnterior: [],
      registroPlacaActual: [],
      isNewOrden: true,
      isNewDiagnostico: true,
      isNewRecetario: true,
      isNewRemision: true,
      isNewCertificados: true,
      isNewIncapacidad: true,
      isNewMediAplicados: true,
      isNewInsumos: true,
      dialogPaqueteOrdenes: false,
      dialogPaqueteProcesosRealizados: false,
      causaexterna: null,
      loading: false,
      loading2: false,
      loadingDiente: false,
      seccionesvista: [],
      seccionesPaginado: [],
      url: "sistema/historiasfacturaciones/",
      secciones: [],
      campos: [],
      medicinas: [],
      servicios: [],
      formHistoria: [],
      formHistoriaValidado: [],
      formOrdenes: [],
      formOrdenesrealizadas: [],
      formDiagnosticos: [],
      formRecetarios: [],
      formRemisiones: [],
      formIncapacidades: [],
      formCertificados: [],
      formMediAplicados: [],
      formInsumos: [],
      listOrdenes: [],
      listOrdenesrealizadas: [],
      listDiagnosticos: [],
      listRecetarios: [],
      listRemisiones: [],
      listIncapacidades: [],
      listCertificados: [],
      listMediAplicados: [],
      listInsumos: [],
      sourceGraficas:[],
      tipocie10: {
        search: null,
        isLoading: false,
        tipocie10: []
      },
      tipodiagnostico: [],
      tipoclasedx: [],
      tipoordenes: [],
      tipocausaexterna: [],
      tipoprocedimiento: [],
      insumos:[],
      dialogCertificados: false,
      CertificadosHeaders: [{
          text: 'Certificado',
          value: 'ciceCertificado',
        },
        {
          text: 'Opciones',
          value: 'actions',
          sortable: false
        },
      ],
      dialogIncapacidades: false,
      IncapacidadesHeaders: [{
          text: 'Fecha inicial',
          value: 'ciceFechainicio',
        },
        {
          text: 'fecha final',
          value: 'ciceFechafinal',
        },
        {
          text: 'Dias',
          value: 'ciceDias',
        },
        {
          text: 'Opciones',
          value: 'actions',
          sortable: false
        },
      ],
      dialogRemisiones: false,
      RemisionesHeaders: [{
          text: 'Cup',
          value: 'tblConfCup.cocuNombre',
        },
        {
          text: 'Observacion',
          value: 'cirmObservacion',
        },
        {
          text: 'Opciones',
          value: 'actions',
          sortable: false
        },
      ],
      dialogOrdenes: false,
      OrdenesHeaders: [{
          text: 'Cup',
          value: 'tblConfCups[0].cocuNombre',
        },
        {
          text: 'Tipo de orden',
          value: 'orden.comaNombrelargo',
        },
        {
          text: 'Tipo de procedimiento',
          value: 'tipoprocedimiento.comaNombrelargo',
        },
        {
          text: 'Cantidad',
          value: 'ciorCantidad',
        },
        {
          text: 'Opciones',
          value: 'actions',
          sortable: false
        },
      ],
      dialogRecetarios: false,
      RecetariosHeaders: [{
          text: 'Medicamento',
          value: 'tblMediMedicamento.mediNombre',
        },
        {
          text: 'Frecuencia de horas',
          value: 'cireFrecuenciahoras',
        },
        {
          text: 'Duracion en dias',
          value: 'cireDuraciondias',
        },
        {
          text: 'Dosis',
          value: 'cireCantidaddosis',
        },
        {
          text: 'Cantidad',
          value: 'cireCantidadprescrita',
        },
        {
          text: 'Opciones',
          value: 'actions',
          sortable: false
        },
      ],
      dialogMediAplicados: false,
      MediAplicadosHeaders: [{
          text: 'Medicamento',
          value: 'medicamento.mediNombre',
        },
        {
          text: 'Cantidad',
          value: 'cimaCantidad',
        },
        {
          text: 'Opciones',
          value: 'actions',
          sortable: false
        },
      ],
      dialogInsumos: false,
      InsumosHeaders: [{
          text: 'Insumo',
          value: 'insumo.coinNombre',
        },
        {
          text: 'Cantidad',
          value: 'insaCantidad',
        },
        {
          text: 'Opciones',
          value: 'actions',
          sortable: false
        },
      ],
      dialogDiagnosticos: false,
      diagnosticosHeaders: [

        {
          text: 'Cie10',
          value: 'cie10.comaNombrelargo',
        },
        {
          text: 'Clase',
          value: 'clase.comaNombrelargo',
        },
        {
          text: 'Diagnostico',
          value: 'diagnostico.comaNombrelargo',
        },
        {
          text: 'Opciones',
          value: 'actions',
          sortable: false
        },
      ],
      dialogProcesosRealizados: false,
      ProcesosRealizadosHeaders: [
        {
          text: 'Cup',
          value: 'tblConfCups[0].cocuNombre',
        },
        {
          text: 'Tipo de orden',
          value: 'orden.comaNombrelargo',
        },
        {
          text: 'Tipo de procedimiento',
          value: 'tipoprocedimiento.comaNombrelargo',
        },
        {
          text: 'Cantidad',
          value: 'ciorCantidad',
        },
        {
          text: 'Opciones',
          value: 'actions',
          sortable: false
        },
      ],
      odontogramaHeaders: [{
          text: 'Diente',
          value: 'ciodDiente',
        },
        {
          text: 'Parte',
          value: 'ciodParte',
        },
        {
          text: 'Convencion',
          value: 'ciodConvencion',
        },
        {
          text: 'Opciones',
          value: 'actions',
          sortable: false
        },
      ],
      placaHeaders: [{
          text: 'Diente',
          value: 'ciodDiente',
        },
        {
          text: 'Parte',
          value: 'ciodParte',
        },
        {
          text: 'Opciones',
          value: 'actions',
          sortable: false
        },
      ],
      script: '',
      cariados: [],
      obturados: [],
      odontogramaCargado: false,
      placaCargado: false,
      pagAnterior:0,
      pagA:1,
      primero:true,
      tipoconfig:[],
      habanexo:false,
    };
  },

  validations: {
    causaexterna: {
      required,
    },
    formHistoriaValidado: {
      $each: {
        cihfValor: {
          required,
        },
      }
    },
    formHistoria: {
      $each: {
        cihfValor: {
          required,
        },
      }
    },
    formHistoria2: {
      $each: {
        cihfValor: {
          required,
        },
      }
    },
    formOrdenes: {
      $each: {
        ciorTipoOrden: {
          required,
        },
        ciorCantidad: {
          required,
        },
       /* ciorObservacion: {
          required,
        },*/
        cocuId: {
          required,
        },
      }
    },
    formOrdenesrealizadas: {
      $each: {
        ciorTipoOrden: {
          required,
        },
        ciorObservacion: {
          required,
        },
        ciorCantidad: {
          required,
        },
        cocuId: {
          required,
        },
      }
    },
    formDiagnosticos: {
      $each: {
        cidiTipoCie10: {
          required,
        },

        cidiTipoDiagnostico: {
          required,
        },

        cidiTipoClase: {
          required,
        },
      }
    },
    formRecetarios: {
      $each: {
        cireFrecuenciahoras: {
          required,
        },

        cireDuraciondias: {
          required,
        },

        cireCantidadprescrita: {
          required,
        },
        cireCantidaddosis: {
          required,
        },

      }
    },
    formMediAplicados: {
      $each: {
        mediId: {
          required,
        },
        cimaCantidad: {
          required,
        },
      }
    },
    formInsumos: {
      $each: {
        coinId: {
          required,
        },
        insaCantidad: {
          required,
        },
      }
    },
    formRemisiones: {
      $each: {

        cirmObservacion: {
          required,
        },

        cocuId: {
          required,
        },

      }
    },
    formIncapacidades: {
      $each: {
        ciceFechainicio: {
          required,
        },
        ciceFechafinal: {
          required,
        },
        ciceTipo: {
          required,
        },
        cifaId: {
          required,
        },
        ciceDias: {
          required,
        },
      }
    },
    formCertificados: {
      $each: {
        ciceTipo: {
          required,
        },
        cifaId: {
          required,
        },
        ciceCertificado: {
          required,
        },
      }
    },
    formOdontograma: {
      $each: {
        ciodConvencion: {
          required,
        },
        ciodDiente: {
          required,
        },
      }
    },
    formPlaca: {
      $each: {
        ciodParte: {
          required,
        },
        ciodDiente: {
          required,
        },
      }
    },
  },
  created: async function() {
    this.$store.commit('setCargaDatos', true);
    let [
      insumos,
      medicinas,
      tipodiagnostico,
      tipoclasedx,
      tipoordenes,
      tipocausaexterna,
      tipoprocedimiento,
      tipovia,
      tipopresentacion,
      tipounidad,
      tipoconfig
    ] = await this.$apiService.indexAll([
    axios.get("sistema/insumos/index", {
        headers: {
          token: this.$cookies.get("token")
        }
      }),
      axios.get("sistema/medicinas/index", {
        headers: {
          token: this.$cookies.get("token")
        }
      }),
      axios.get("sistema/maestra/index/TBL_TIPOSDIAGNOSTICOS", {
        headers: {
          token: this.$cookies.get("token")
        }
      }),
      axios.get("sistema/maestra/index/TBL_TIPOSCLASEDX", {
        headers: {
          token: this.$cookies.get("token")
        }
      }),
      axios.get("sistema/maestra/index/TBL_TIPOSORDENES", {
        headers: {
          token: this.$cookies.get("token")
        }
      }),
      axios.get("sistema/maestra/index/TBL_TIPOCAUSAEXT", {
        headers: {
          token: this.$cookies.get("token")
        }
      }),
      axios.get("sistema/maestra/index/TBL_TIPOSPROCEDIMIENTOS", {
        headers: {
          token: this.$cookies.get("token")
        }
      }),
      axios.get("sistema/maestra/index/TBL_TIPOVIASMED", {
        headers: {
          token: this.$cookies.get("token")
        }
      }),
      axios.get("sistema/maestra/index/TBL_TIPOPRESENTACIONMED", {
        headers: {
          token: this.$cookies.get("token")
        }
      }),
      axios.get("sistema/maestra/index/TBL_TIPOUNIDADES", {
        headers: {
          token: this.$cookies.get("token")
        }
      }),
      axios.get("sistema/maestra/index/TBL_TIPOCOFIG", {
        headers: {
          token: this.$cookies.get("token")
        }
      })

    ]);
    this.medicinas = medicinas.data;
    this.medicinas = this.medicinas.filter(e => e.mediActivo != 0);
    this.insumos = insumos.data;
    this.insumos = this.insumos.filter(e => e.coinActivo != 0);
    this.tipodiagnostico = tipodiagnostico.data;
    this.tipoclasedx = tipoclasedx.data;
    this.tipoordenes = tipoordenes.data;
    this.tipocausaexterna = tipocausaexterna.data;
    this.tipoprocedimiento = tipoprocedimiento.data;
    this.tipovia = tipovia.data;
    this.tipopresentacion = tipopresentacion.data;
    this.tipounidad = tipounidad.data;
    this.tipoconfig = tipoconfig.data;
    if(this.tipoconfig.some(e => e.comaNombrecorto == 'HAB_ANEXOS')){
     this.habanexo = this.tipoconfig.find(e => e.comaNombrecorto == 'HAB_ANEXOS').comaValor == 'SI' ? true:false;
    }
    if (this.$route.params.id) {
      const servicio = this.$route.params.servicio;
      try {
        if (this.getPermiso("crearHistorias")) {
          this.factura = await this.$apiService.index("cita/facturaciones/view/" + this.$route.params.id).then(data => data);
          this.paciente = this.factura.tblCitaCita.tblConfPersonaspacientes[0];
          this.causaexterna = this.factura.cifaTipoCausa;
          let edad = (new Date(this.factura.cifaFecha.replace(/-+/g, '/')).getTime() - new Date(this.factura.tblCitaCita.tblConfPersonaspacientes[0].coppFechanacimiento.replace(/-+/g, '/')).getTime()) / 86400000;
          console.log(edad)
          this.sourceGraficas = await this.$apiService.index("sistema/historiagraficas/index?sexo=" + this.paciente.sexo.comaNombrecorto).then(data => data);
          this.sourceGraficas = this.sourceGraficas.filter(e => e.cogpEdadMin <= edad && e.cogpEdadMax >= edad );
          this.sourceGraficas = this.sourceGraficas.map((item) =>{return {...item , data:JSON.parse(item.cogpData)}});
          localStorage.setItem("sourceGraficas",JSON.stringify(this.sourceGraficas));
          let cita  = await this.$apiService.index("cita/citas/view/" + this.factura.citaId).then(data => data);
          let isOdonto = ['ODONTOLOGÍA', 'HIGIENE ORAL', 'ORTODONCIA'];
          if (isOdonto.some(e =>  this.factura.tblCitaCita.tblConfContratosxservicios[0].tblConfServicios[0].coseNombre.indexOf(e) > -1)) {
            this.formOdontograma.push({
              ciodConvencion: null,
              ciodDiente: null,
              ciodParte: null,
              cifaId: this.factura.cifaId,
              ciodPlaca: 0,
              ciodTipo: 1
            });
            this.dientesplaca = this.generarDientes('PLACA');
            let yatiene = await this.$apiService.index("cita/odontologicas/index/" + this.factura.cifaId + "/0").then(data => data);
            if (yatiene.length == 0) {
              let anterior = await this.$apiService.index(`cita/odontologicas/ultimo/${this.factura.tblCitaCita.coppId}/${this.factura.tblCitaCita.cocsId}/0`).then(data => data);
              if (anterior.length > 0) {
                this.registroPlacaAnterior = anterior;
                let form = [];
                anterior.forEach(diente => {
                  let d = this.dientesplaca.find(e => e.numero == diente.ciodDiente);
                  if (d) {
                    if (d.partes.parteLeftTop.id == diente.ciodParte) d.partes.parteLeftTop.placa = diente.ciodPlaca;
                    if (d.partes.parteRightTop.id == diente.ciodParte) d.partes.parteRightTop.placa = diente.ciodPlaca;
                    if (d.partes.parteLeftBottom.id == diente.ciodParte) d.partes.parteLeftBottom.placa = diente.ciodPlaca;
                    if (d.partes.parteRightBottom.id == diente.ciodParte) d.partes.parteRightBottom.placa = diente.ciodPlaca;
                    if (d.partes.parteMedio.id == diente.ciodParte) d.partes.parteMedio.placa = diente.ciodPlaca;
                  }
                  form.push({
                    ciodDiente: diente.ciodDiente,
                    ciodParte: diente.ciodParte,
                    cifaId: this.factura.cifaId,
                    ciodPlaca: diente.ciodPlaca,
                    ciodTipo: diente.ciodTipo
                  });
                });
                await this.$apiService.create("cita/odontologicas/createnuevo", form).then(data => data);
              }

            } else {
              this.registroPlacaActual = yatiene;
              yatiene.forEach(diente => {
                let d = this.dientesplaca.find(e => e.numero == diente.ciodDiente);
                if (d) {
                  if (d.partes.parteLeftTop.id == diente.ciodParte) d.partes.parteLeftTop.placa = diente.ciodPlaca;
                  if (d.partes.parteRightTop.id == diente.ciodParte) d.partes.parteRightTop.placa = diente.ciodPlaca;
                  if (d.partes.parteLeftBottom.id == diente.ciodParte) d.partes.parteLeftBottom.placa = diente.ciodPlaca;
                  if (d.partes.parteRightBottom.id == diente.ciodParte) d.partes.parteRightBottom.placa = diente.ciodPlaca;
                  if (d.partes.parteMedio.id == diente.ciodParte) d.partes.parteMedio.placa = diente.ciodPlaca;
                }
              });

            }
            this.dientesodontograma = this.generarDientes('ODONTOGRAMA');
            let yatieneO = await this.$apiService.index("cita/odontologicas/index/" + this.factura.cifaId + "/1").then(data => data);
            if (yatieneO.length == 0) {
              let anteriorO = await this.$apiService.index(`cita/odontologicas/ultimo/${this.factura.tblCitaCita.coppId}/${this.factura.tblCitaCita.cocsId}/1`).then(data => data);
              if (anteriorO.length > 0) {
                this.odontograma = anteriorO;
                let form = [];
                anteriorO.forEach(diente => {
                  let d = this.dientesodontograma.find(e => e.numero == diente.ciodDiente);
                  if (d) {
                    if (diente.ciodParte) {
                      if (d.partes.parteTop.id == diente.ciodParte) d.partes.parteTop.convencion = diente.ciodConvencion;
                      if (d.partes.parteBottom.id == diente.ciodParte) d.partes.parteBottom.convencion = diente.ciodConvencion;
                      if (d.partes.parteLeftTop.id == diente.ciodParte) d.partes.parteLeftTop.convencion = diente.ciodConvencion;
                      if (d.partes.parteRightTop.id == diente.ciodParte) d.partes.parteRightTop.convencion = diente.ciodConvencion;
                      if (d.partes.parteLeftBottom.id == diente.ciodParte) d.partes.parteLeftBottom.convencion = diente.ciodConvencion;
                      if (d.partes.parteRightBottom.id == diente.ciodParte) d.partes.parteRightBottom.convencion = diente.ciodConvencion;
                      if (d.partes.parteMedio.id == diente.ciodParte) d.partes.parteMedio.convencion = diente.ciodConvencion;
                    } else {
                      d.convencion = diente.ciodConvencion;
                    }
                  }
                  form.push({
                    ciodDiente: diente.ciodDiente,
                    ciodParte: diente.ciodParte,
                    ciodConvencion: diente.ciodConvencion,
                    cifaId: this.factura.cifaId,
                    ciodPlaca: diente.ciodPlaca,
                    ciodTipo: diente.ciodTipo
                  });
                });
                await this.$apiService.create("cita/odontologicas/createnuevo", form).then(data => data);
              }

            } else {
              this.odontograma = yatieneO;
              yatieneO.forEach(diente => {
                let d = this.dientesodontograma.find(e => e.numero == diente.ciodDiente);
                if (d) {
                  if (diente.ciodParte) {
                    if (d.partes.parteTop.id == diente.ciodParte) d.partes.parteTop.convencion = diente.ciodConvencion;
                    if (d.partes.parteBottom.id == diente.ciodParte) d.partes.parteBottom.convencion = diente.ciodConvencion;
                    if (d.partes.parteLeftTop.id == diente.ciodParte) d.partes.parteLeftTop.convencion = diente.ciodConvencion;
                    if (d.partes.parteRightTop.id == diente.ciodParte) d.partes.parteRightTop.convencion = diente.ciodConvencion;
                    if (d.partes.parteLeftBottom.id == diente.ciodParte) d.partes.parteLeftBottom.convencion = diente.ciodConvencion;
                    if (d.partes.parteRightBottom.id == diente.ciodParte) d.partes.parteRightBottom.convencion = diente.ciodConvencion;
                    if (d.partes.parteMedio.id == diente.ciodParte) d.partes.parteMedio.convencion = diente.ciodConvencion;
                  } else {
                    d.convencion = diente.ciodConvencion;
                  }
                }
              });

            }
          }
          

          
          this.formHistoria = await this.$apiService.index(this.url + "index/" + this.$route.params.id).then(data => data);
          if (this.formHistoria.length > 0) {
            if(cita.estado.comaNombrecorto == 'ASISTIDA'){
              let formIdSecciones = this.formHistoria.map(item => {
                  return item.tblConfHistoriaseccione.seccion.hiseId
              });
              formIdSecciones = formIdSecciones.filter((item,index)=>{
                return formIdSecciones.indexOf(item) === index;
              })
              this.secciones = await this.$apiService.create(`sistema/historiasecciones/secciones-by-ids/${this.factura.tblCitaCita.tblConfContratosxservicios[0].coseId}` ,formIdSecciones).then(data => data);
              this.isNew = false;
            }else{
              this.secciones = await this.$apiService.index(`sistema/historiasecciones/secciones/${this.factura.tblCitaCita.tblConfContratosxservicios[0].coseId}` ).then(data => data);
              this.isNew = true;
            }
            
            this.primero = false;
            
          } else {
            this.secciones = await this.$apiService.index(`sistema/historiasecciones/secciones/${this.factura.tblCitaCita.tblConfContratosxservicios[0].coseId}` ).then(data => data);
            this.primero = true;
            this.isNew = true;

            const max = await this.$apiService.index(`cita/facturaciones/max/${this.factura.tblCitaCita.coppId}/${this.factura.cifaFecha}/${servicio}`).then(data => data);
            if (max) {
              this.formHistoria = await this.$apiService.index(this.url + "index/" + max).then(data => data);
              for (let h of this.formHistoria) {
                h.cifaId = this.$route.params.id;
                h.cihfId = null;
              }
            } else {
              if (this.factura.tblCitaCita.tblConfContratosxservicios[0].tblConfServicios[0].primeravez) {
                let max2 = await this.$apiService.index(`cita/facturaciones/max/${this.factura.tblCitaCita.coppId}/${this.factura.cifaFecha}/${ this.factura.tblCitaCita.tblConfContratosxservicios[0].tblConfServicios[0].primeravez.coseId}`).then(data => data);
                if (max2) {
                  let primera = await this.$apiService.index(this.url + "index/" + max2).then(data => data);
                  if (primera.length > 0) {
                    this.secciones.forEach(seccion => {
                      seccion.campos.forEach(campo => {
                        if (primera.find(e => e.hiseId == campo.hiseId)) {
                          primera.find(e => e.hiseId == campo.hiseId).cifaId = this.$route.params.id;
                          primera.find(e => e.hiseId == campo.hiseId).cihfId = null;
                          this.formHistoria.push(primera.find(e => e.hiseId == campo.hiseId));
                        }
                      });
                    });
                  }
                }
              }
            }
          }
          localStorage.setItem("antencion",JSON.stringify(this.formHistoria));
          let secciones = [];
          this.secciones = this.secciones.filter(e => e.hiseEstado);
          if (this.$route.params.triage) {
            if (this.$route.params.triage == 1) {
                this.secciones = this.secciones.filter(e => e.hiseTriage);
            }
          }
          this.secciones = this.secciones.filter(e => (e.hiseSexo == null || e.hiseSexo == 0 || e.hiseSexo == ((this.paciente.sexo.comaNombrecorto == 'M') ? 1 : 2)) &&
            (e.hiseEdadinicio == null || e.hiseEdadinicio == '' ||((new Date().getTime() - new Date(this.paciente.coppFechanacimiento.replace(/-+/g, '/')).getTime()) / 86400000) >= e.hiseEdadinicio) &&
            (e.hiseEdadfinal == null || e.hiseEdadfinal == '' || ((new Date().getTime() - new Date(this.paciente.coppFechanacimiento.replace(/-+/g, '/')).getTime()) / 86400000) <= e.hiseEdadfinal));
          this.secciones.forEach((seccion, index) => {
            if (seccion.campos.length > 0 && seccion.hiseEstado) {
              seccion.campos.sort((a, b) => a.hiseOrden - b.hiseOrden);

              /* this.campos.push({
                   hiseId: seccion.hiseCodigo,
                   campos: seccion.campos.sort((a, b) => a.hiseOrden - b.hiseOrden)
               });*/
              seccion.campos = seccion.campos.filter(e => e.hiseEstado);
              seccion.campos = seccion.campos.filter(e => (e.hiseSexo == null || e.hiseSexo == 0 || e.hiseSexo == ((this.paciente.sexo.comaNombrecorto == 'M') ? 1 : 2)) &&
                (e.hiseEdadinicio == null  || e.hiseEdadinicio == '' || ((new Date().getTime() - new Date(this.paciente.coppFechanacimiento.replace(/-+/g, '/')).getTime()) / 86400000) >= e.hiseEdadinicio) &&
                (e.hiseEdadfinal == null || e.hiseEdadfinal == '' || ((new Date().getTime() - new Date(this.paciente.coppFechanacimiento.replace(/-+/g, '/')).getTime()) / 86400000) <= e.hiseEdadfinal));
              seccion.campos.forEach(campo => {
                if (campo.hiseEstado) {
                  if (this.formHistoria.find(e => e.hiseId == campo.hiseId) == undefined) {
                    this.formHistoria.push({
                      cihfId: null,
                      menu: false,
                      cihfValor: (campo.hiseDefault) ? campo.hiseDefault : ((campo.tipodato.comaNombrecorto == 'SI/NO') ? 'NO' : (campo.tipodato.comaNombrecorto == 'TEXTAREA') ? 'NORMAL':null),
                      cifaId: this.$route.params.id,
                      hiseId: campo.hiseId
                    });
                  }
                  if (campo.hiseRequerido) {
                    this.formHistoriaValidado.push({
                      cihfValor: null,
                      hiseId: campo.hiseId
                    });
                  }
                }

              });
            }
            if ((index % 4) == 0 && index != 0) {
              secciones.push(seccion);
              this.seccionesPaginado.push({
                pag: index / 4,
                secciones: secciones
              });
              secciones = [];
            } else {
              if ((index + 1) == this.secciones.length) {
                secciones.push(seccion);
                this.seccionesPaginado.push({
                  pag: (this.seccionesPaginado.length + 1),
                  secciones: secciones
                });
                secciones = [];
              } else {
                secciones.push(seccion);
              }

            }

          });
          if (this.seccionesPaginado.find(e => e.pag == 1)) {
            this.seccionesvista = this.seccionesPaginado.find(e => e.pag == 1).secciones;
          }
          this.causaexterna = this.tipocausaexterna.find(e => e.comaNombrelargo == 'Otra').comaId;
          if (this.isNew) {
            this.nuevo = true;
            await this.$apiService.update("cita/facturaciones/horas/" + this.$route.params.id, {
              cifaHorainicio: new Date().hhmm()
            }).then(data => data);
            if(this.factura.tblCitaCita.tblConfContratosxservicios[0].tblConfServicios[0].coseRuta){
              await this.$apiService.create("cita/demanda/createDetalle", {
              cifaId:  this.$route.params.id,
              ruta:this.factura.tblCitaCita.tblConfContratosxservicios[0].tblConfServicios[0].coseRuta,
              coppId:this.paciente.tblConfPersonasnaturale.tblConfPersona.copeId
            }).then(data => data);
            }
          }
        }
      } catch (error) {
        console.log(error);
        this.$swal.fire({
          title: "Algo sucedio!",
          text: "contacta con el administrador",
          icon: "error"
        });
      }
    }
    this.$store.commit('setCargaDatos', false);
  },
  updated() {

    if (document.getElementById('scripthistoria') && this.secciones.length > 0 && !this.loading2) {
      if(this.pagAnterior != this.pag){
        document.getElementById('scripthistoria').innerHTML = '';
        this.pagAnterior = this.pag;
      }
      if (document.getElementById('scripthistoria').innerHTML.length == 0) {
        let s = '';
        for (let i = 0; i < this.secciones.length; i++) {
          const data = this.secciones[i].campos;
          if (this.secciones[i].hiseScript) {
            s = s + this.secciones[i].hiseScript;
          }
          if (data) {
            for (let j = 0; j < data.length; j++) {
              if (data[j].hiseScript) {
                s = s + data[j].hiseScript;
              }
            }
          }
        }
        this.script = `<script>${ s }<${"/"}script>`;
        postscribe('#scripthistoria', this.script);
      }
    }
    if (document.getElementById('canvasodontograma') && this.seccionesvista.length > 0 && this.odontogramaCargado == false) {
      this.odontogramaCargado = true;
      if (document.getElementById('canvasodontograma').innerHTML.length == 0) {
        if (this.seccionesvista.find(e => e.hiseNombre == 'ODONTOGRAMA')) {
          this.cargarodontograma();
          window.setTimeout(() => {
            var img = document.getElementById("imgcanvasodontograma");
            var canvas = document.getElementById("contenedorcanvasodontograma");
            img.removeAttribute("hidden");
            img.setAttribute("src", this.canvasodontograma.toDataURL("image/png"));
            canvas.setAttribute("hidden", true);

          }, 2000);

        }
      }
    }
    if (document.getElementById('canvasplaca') && this.seccionesvista.length > 0 && this.placaCargado == false) {
      this.placaCargado = true;
      if (document.getElementById('canvasplaca').innerHTML.length == 0) {
        if (this.seccionesvista.find(e => e.hiseNombre == 'REGISTRO DE CONTROL DE PLACA')) {
          this.cargarplaca();
          window.setTimeout(() => {
            var img = document.getElementById("imgcanvasplaca");
            var canvas = document.getElementById("contenedorcanvasplaca");
            img.removeAttribute("hidden");
            img.setAttribute("src", this.canvas.toDataURL("image/png"));
            canvas.setAttribute("hidden", true);

          }, 2000);
        }
      }
    }
  },

  methods: {
    ejecutarInput(id, valor) {
      let event = new Event('input');
      document.getElementById(id).value = valor;
      document.getElementById(id).dispatchEvent(event);
    },
    async cancelar() {
      let result = await this.$swal.fire({
        title: "Estas seguro?",
        text: "Esta seguro de que desea salir!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Si, salir!"
      }).then(result => result.value);
      if (result) {
        this.$router.push('/atencion');
      }
    },
    cambiarFecha(item) {
      if (item.ciceFechainicio && item.ciceDias) {
        item.ciceFechafinal = new Date(item.ciceFechainicio.replace(/-+/g, '/')).addDays((parseInt(item.ciceDias) - 1)).yyyymmdd();
      }
    },
    async cargarTodo() {
      await this.cargarDiagnosticos();
      await this.cargarOrdenes();
      await this.cargarOrdenesrealizadas();
      await this.cargarRecetarios();
      await this.cargarRemisiones();
      await this.cargarIncapacidadCertificado('INCAPACIDAD');
      await this.cargarIncapacidadCertificado('CERTIFICADO');
      await this.cargarInsumos();
      await this.cargarMediAplicados();
    },
    async cargarDiagnosticos() {
      this.listDiagnosticos = [];
      let formDiagnosticos = await this.$apiService.index("cita/asistenciales/atender/index/" + this.$route.params.id + "/diagnostico").then(data => data);
      if (formDiagnosticos.length > 0) {
        for (let i = 0; i < formDiagnosticos.length; i++) {
          let cie10 = await this.$apiService.index("sistema/maestra/view/" + formDiagnosticos[i].cidiTipoCie10).then(data => data);
          formDiagnosticos[i].tipocie10 = {
            search: null,
            isLoading: false,
            tipocie10: [cie10]
          };
          this.listDiagnosticos.push(formDiagnosticos[i]);
        }
        this.isNewDiagnostico = false;
      } else {
        this.isNewDiagnostico = true;
        this.agregarDiagnostico();
      }
    },
    async cargarOrdenes() {
      this.listOrdenes = await this.$apiService.index("cita/asistenciales/atender/index/" + this.$route.params.id + "/orden?tipo=AUTORIZACION").then(data => data);
      if (this.listOrdenes.length > 0) {
        for (var i = 0; i < this.listOrdenes.length; i++) {
          var r = this.listOrdenes[i];
          r.cups = {
            search: null,
            isLoading: false,
            cups: [r.tblConfCups[0]]
          };
        }
        this.isNewOrden = false;
      } else {
        this.isNewOrden = true;
      }
    },
    async cargarOrdenesrealizadas() {
      this.listOrdenesrealizadas = await this.$apiService.index("cita/asistenciales/atender/index/" + this.$route.params.id + "/orden?tipo=REALIZADO").then(data => data);
      if (this.listOrdenesrealizadas.length > 0) {
        for (var i = 0; i < this.listOrdenesrealizadas.length; i++) {
          var r = this.listOrdenesrealizadas[i];
          r.cups = {
            search: null,
            isLoading: false,
            cups: [r.tblConfCups[0]]
          };
        }
        this.isNewOrdenrealizada = false;
      } else {
        this.isNewOrdenrealizada = true;
      }
    },
    async cargarRecetarios() {
      this.listRecetarios = await this.$apiService.index("cita/asistenciales/atender/index/" + this.$route.params.id + "/recetario").then(data => data);

      if (this.listRecetarios.length > 0) {
        this.isNewRecetario = false;
      } else {
        this.isNewRecetario = true;
        //this.agregarRecetario();
      }
    },
    async cargarMediAplicados() {
      this.listMediAplicados = await this.$apiService.index("cita/asistenciales/atender/index/" + this.$route.params.id + "/mediaplicados").then(data => data);

      if (this.listMediAplicados.length > 0) {
        this.isNewMediAplicados = false;
      } else {
        this.isNewMediAplicados = true;
        //this.agregarRecetario();
      }
    },
    async cargarInsumos() {
      this.listInsumos = await this.$apiService.index("cita/asistenciales/atender/index/" + this.$route.params.id + "/insumos").then(data => data);

      if (this.listInsumos.length > 0) {
        this.isNewInsumos = false;
      } else {
        this.isNewInsumos = true;
        //this.agregarRecetario();
      }
    },
    async cargarRemisiones() {
      this.listRemisiones = await this.$apiService.index("cita/asistenciales/atender/index/" + this.$route.params.id + "/remision").then(data => data);
      if (this.listRemisiones.length > 0) {
        for (var i = 0; i < this.listRemisiones.length; i++) {
          var r = this.listRemisiones[i];
          r.cups = {
            search: null,
            isLoading: false,
            cups: [r.tblConfCup]
          };
        }
        this.isNewRemision = false;
      } else {
        this.isNewRemision = true;
      }
    },
    async cargarIncapacidadCertificado(tipo) {
      if (tipo == 'INCAPACIDAD') {
        let data = await this.$apiService.index("cita/asistenciales/atender/index/" + this.$route.params.id + "/incapacidadcertificado?tipo=" + tipo).then(data => data);
        this.listIncapacidades = (data.length > 0) ? data : [];
        (this.listIncapacidades.length) ? this.isNewIncapacidad = false: this.isNewIncapacidad = true;
      } else {
        let data = await this.$apiService.index("cita/asistenciales/atender/index/" + this.$route.params.id + "/incapacidadcertificado?tipo=" + tipo).then(data => data);
        this.listCertificados = (data.length > 0) ? data : [];
        (this.listCertificados.length) ? this.isNewCertificados = false: this.isNewCertificados = true;
      }
    },
    async cargar() {
      if ((!(this.pagA + 1 < this.pag) && this.primero) || !this.primero) {
        if (!this.insertando && this.seccionesvista.length > 0) {
          localStorage.setItem("antencion",JSON.stringify(this.formHistoria));
          this.insertando = true;
          this.loading2 = true;
          var formHistoria2 = [];
          this.seccionesvista.forEach(s => {
            s.campos.forEach(c => {
              if (this.formHistoria.find(e => e.hiseId == c.hiseId) != undefined) {
                formHistoria2.push(this.formHistoria.find(e => e.hiseId == c.hiseId))
              }
            });

          });
          this.$v.formHistoria2.$touch();
          this.$v.causaexterna.$touch();

          if (!this.$v.formHistoria2.$error && !this.$v.causaexterna.$error) {
            try {
              if (this.isNew) {
                if (this.getPermiso("crearHistorias")) {
                  await this.$apiService.update("cita/facturaciones/update/" + this.$route.params.id, {
                    cifaTipoCausa: this.causaexterna
                  }).then(data => data);
                  let data = await this.$apiService.create(this.url + "create", formHistoria2).then(data => data);
                  if (data) {
                    //this.isNew = false;
                    //this.pag = this.pag + 1;
                    this.seccionesvista = this.seccionesPaginado.find(e => e.pag == this.pag).secciones;
                    this.$nextTick().then(() => {
                      if (this.seccionesvista.find(e => e.hiseNombre == 'ODONTOGRAMA')) {
                        this.cargarodontograma();
                      }
                      if (this.seccionesvista.find(e => e.hiseNombre == 'REGISTRO DE CONTROL DE PLACA')) {
                        this.cargarplaca();
                      }
                    });

                  }
                }

              } else {

                if (this.getPermiso("editarHistorias")) {
                  await this.$apiService.update("cita/facturaciones/update/" + this.$route.params.id, {
                    cifaTipoCausa: this.causaexterna
                  }).then(data => data);
                  let data = await this.$apiService.update(this.url + "update/" + this.$route.params.id, formHistoria2).then(data => data);
                  
                  if (data) {
                    //this.pag = this.pag + 1;
                    this.seccionesvista = this.seccionesPaginado.find(e => e.pag == this.pag).secciones;
                    this.$nextTick().then(() => {
                      if (this.seccionesvista.find(e => e.hiseNombre == 'ODONTOGRAMA')) {
                        this.cargarodontograma();
                      }
                      if (this.seccionesvista.find(e => e.hiseNombre == 'REGISTRO DE CONTROL DE PLACA')) {
                        this.cargarplaca();
                      }
                    });

                  }
                }

              }
            } catch (error) {
              this.loading = false;
              console.log(error);
              this.$swal.fire({
                title: "Algo sucedio!",
                text: "contacta con el administrador",
                icon: "error"
              });
            }
          } else {
            this.$swal.fire({
              title: "Campos vacios de la historia!",
              text: "por favor diligenciarlos",
              icon: "warning"
            });
          }

          this.loading2 = false;
          // }
          this.insertando = false;
          this.pagA = this.pag;
        }
      }else{
        this.$swal.fire({
                  title: "Ups!",
                  text: "No puedes saltar la pagina siguiente",
                  icon: "warning",
                  confirmButtonText: "Ok"
                });
        this.pag = this.pagA;
      }
    },
    calcularCantidad(items) {
      items.cireCantidadprescrita = items.cireCantidaddosis * ((parseInt(items.cireDuraciondias) * 24) / parseInt(items.cireFrecuenciahoras));
    },
    async searchCIE10(tipocie10) {
      tipocie10.isLoading = true;
      if (tipocie10.search.length > 0 && tipocie10.search.toString().replace(/ /g, "") != "") {
        tipocie10.tipocie10 = await this.$apiService.index(`sistema/maestra/buscar3/TBL_TIPOSCIE10?comaNombrecorto=${tipocie10.search}&comaNombrelargo=${tipocie10.search}`).then(data => data);
      }
      tipocie10.isLoading = false;
    },
    async searchCup(cups) {
      cups.isLoading = true;
      if (cups.search.length > 0 && cups.search.toString().replace(/ /g, "") != "") {
        await this.$apiService.index("sistema/cups/buscar/" + cups.search.toUpperCase()).then(data => {
          cups.isLoading = false;
          cups.cups = data;

        });
      }

    },
    getValues(values) {
      const lista = [];

      var datos = values.split('|');
      for (let i = 0; i < datos.length; i++) {
        let d = datos[i];
        lista.push({
          texts: d.split(';')[0],
          values: d.split(';')[1]
        });
      }
      return lista;

    },
    getMaestraName(id, maestra) {
      if (id != null) {
        var ma = maestra.filter(e => e.comaNombrecorto == id);
        if (ma.length > 0) {
          var m = ma[0];
          return m.comaId;
        }
      }

    },
    cie10filter(item, queryText = '') {
      return (item.comaNombrecorto.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1) || (item.comaNombrelargo.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1)
    },
    async agregarHistoria() {
      this.loading = true;
      /* this.formHistoria.forEach((element) => {
           if (this.formHistoriaValidado.find(e => e.hiseCodigo == element.hiseCodigo)) {
               this.formHistoriaValidado.find(e => e.hiseCodigo == element.hiseCodigo).cihfValor = element.cihfValor
           }
       });

       this.$v.formHistoriaValidado.$touch();
       this.$v.formHistoria.$touch();
       this.$v.causaexterna.$touch();

       if (!this.$v.formHistoriaValidado.$error && !this.$v.causaexterna.$error) {*/
        localStorage.setItem("antencion",JSON.stringify(this.formHistoria));
      var formHistoria2 = [];
      this.seccionesvista.forEach(s => {
        s.campos.forEach(c => {
          if (this.formHistoria.find(e => e.hiseId == c.hiseId) != undefined) {
            formHistoria2.push(this.formHistoria.find(e => e.hiseId == c.hiseId))
          }
        });

      });
      this.$v.formHistoria2.$touch();
      this.$v.causaexterna.$touch();

      if (!this.$v.formHistoria2.$error && !this.$v.causaexterna.$error) {
        try {
          if (this.isNew) {
            if (this.getPermiso("crearHistorias")) {
              await this.$apiService.update("cita/facturaciones/update/" + this.$route.params.id, {
                cifaTipoCausa: this.causaexterna
              }).then(data => data);
              let data = await this.$apiService.create(this.url + "create", formHistoria2).then(data => data);
              if (data) {
                //this.isNew = false;
                await this.cargarTodo();
                this.$swal.fire({
                  title: "Completado!",
                  text: "Registro exitoso",
                  icon: "success",
                  confirmButtonText: "Ok"
                });
                this.e1 = 2;
              }
            }

          } else {

            if (this.getPermiso("editarHistorias")) {
              await this.$apiService.update("cita/facturaciones/update/" + this.$route.params.id, {
                cifaTipoCausa: this.causaexterna
              }).then(data => data);
              let data = await this.$apiService.update(this.url + "update/" + this.$route.params.id, formHistoria2).then(data => data);
              if (data) {
                //this.isNew = false;
                await this.cargarTodo();
                this.$swal.fire({
                  title: "Completado!",
                  text: "Registro exitoso",
                  icon: "success",
                  confirmButtonText: "Ok"
                });
                this.e1 = 2;
              }
            }

          }
        } catch (error) {
          this.loading = false;
          console.log(error);
          this.$swal.fire({
            title: "Algo sucedio!",
            text: "contacta con el administrador",
            icon: "error"
          });
        }
      } else {
        this.$swal.fire({
          title: "Campos vacios de la historia!",
          text: "por favor diligenciarlos",
          icon: "warning"
        });
      }
      this.loading = false;
    },
    async agregarDiagnosticos() {

      this.$v.formDiagnosticos.$touch();
      this.loading = true;
      if (!this.$v.formDiagnosticos.$error) {
        try {
          let data = null;
          var form = (this.isNewDiagnostico) ? this.formDiagnosticos.concat(this.listDiagnosticos) : this.formDiagnosticos;
          if (this.isNewDiagnostico) {

            if (this.getPermiso("crearHistorias")) {

              data = await this.$apiService.create("cita/asistenciales/atender/create/diagnostico/" + this.$route.params.id, form).then(data => data);

            }

          } else {
            if (this.getPermiso("editarHistorias")) {

              data = await this.$apiService.update("cita/asistenciales/atender/update/diagnostico/" + this.$route.params.id, form).then(data => data);

            }

          }
          if (JSON.stringify(form) !== JSON.stringify(this.listDiagnosticos)) {
            if (data) {

              this.$swal.fire({
                title: "Completado!",
                text: "Registro exitoso",
                icon: "success",
                confirmButtonText: "Ok"
              });
              this.isNewDiagnostico = false;
              this.formDiagnosticos = [];
            }
          }

          await this.cargarDiagnosticos();
        } catch (error) {
          this.loading = false;
          this.$swal.fire({
            title: "Algo sucedio!",
            text: "contacta con el administrador",
            icon: "error"
          });
        }
        this.dialogDiagnosticos = false;
      }

      this.loading = false;
    },
    async agregarOrdenesrealizadas() {
      this.$v.formOrdenesrealizadas.$touch();
      this.loading = true;
      if (!this.$v.formOrdenesrealizadas.$error) {
        try {
          let data = null;
          var form = (this.isNewOrdenrealizada) ? this.formOrdenesrealizadas.concat(this.listOrdenesrealizadas) : this.formOrdenesrealizadas;
          if (this.isNewOrdenrealizada) {
            if (this.getPermiso("crearHistorias")) {

              data = await this.$apiService.create("cita/asistenciales/atender/create/orden?tipo=REALIZADO", form).then(data => data);

            }

          } else {

            if (this.getPermiso("editarHistorias")) {

              data = await this.$apiService.update("cita/asistenciales/atender/update/orden/" + this.$route.params.id + "?tipo=REALIZADO", form).then(data => data);

            }

          }
          if (JSON.stringify(form) !== JSON.stringify(this.listOrdenesrealizadas)) {
            if (data) {

              this.$swal.fire({
                title: "Completado!",
                text: "Registro exitoso",
                icon: "success",
                confirmButtonText: "Ok"
              });
              this.isNewOrdenrealizada = false;
              this.formOrdenesrealizadas = [];
            }
          }

          await this.cargarOrdenesrealizadas();
        } catch (error) {
          this.loading = false;
          this.$swal.fire({
            title: "Algo sucedio!",
            text: "contacta con el administrador",
            icon: "error"
          });
        }
        this.dialogProcesosRealizados = false;
      }

      this.loading = false;
    },
    async agregarRecetarios() {
      this.$v.formRecetarios.$touch();
      this.loading = true;
      if (!this.$v.formRecetarios.$error) {
        try {
          let data = null;
          var form = (this.isNewRecetario) ? this.formRecetarios.concat(this.listRecetarios) : this.formRecetarios;
          if (this.isNewRecetario) {
            if (this.getPermiso("crearHistorias")) {

              data = await this.$apiService.create("cita/asistenciales/atender/create/recetario", form).then(data => data);

            }

          } else {
            if (this.getPermiso("editarHistorias")) {

              data = await this.$apiService.update("cita/asistenciales/atender/update/recetario/" + this.$route.params.id, form).then(data => data);

            }

          }
          if (JSON.stringify(form) !== JSON.stringify(this.listRecetarios)) {
            if (data) {
              for (const d of data) {
                if (this.medicinas.find(e => e.mediId == d.mediId) == undefined) {
                  this.medicinas.push(d.tblMediMedicamento);
                }
              }
              this.$swal.fire({
                title: "Completado!",
                text: "Registro exitoso",
                icon: "success",
                confirmButtonText: "Ok"
              });
              this.isNewRecetario = false;
              this.formRecetarios = [];
            }
          }
          await this.cargarRecetarios();
        } catch (error) {
          this.loading = false;
          this.$swal.fire({
            title: "Algo sucedio!",
            text: "contacta con el administrador",
            icon: "error"
          });
        }
        this.dialogRecetarios = false;
      }

      this.loading = false;
    },
    async agregarMediAplicados() {
      this.$v.formMediAplicados.$touch();
      this.loading = true;
      if (!this.$v.formMediAplicados.$error) {
        try {
          let data = null;
          var form = (this.isNewMediAplicados) ? this.formMediAplicados.concat(this.listMediAplicados) : this.formMediAplicados;
          if (this.isNewMediAplicados) {
            if (this.getPermiso("crearHistorias")) {

              data = await this.$apiService.create("cita/asistenciales/atender/create/mediaplicados", form).then(data => data);

            }

          } else {
            if (this.getPermiso("editarHistorias")) {

              data = await this.$apiService.update("cita/asistenciales/atender/update/mediaplicados/" + this.$route.params.id, form).then(data => data);

            }

          }
          if (JSON.stringify(form) !== JSON.stringify(this.listMediAplicados)) {
            if (data) {
              this.$swal.fire({
                title: "Completado!",
                text: "Registro exitoso",
                icon: "success",
                confirmButtonText: "Ok"
              });
              this.isNewMediAplicados = false;
              this.formMediAplicados = [];
            }
          }
          await this.cargarMediAplicados();
        } catch (error) {
          this.loading = false;
          this.$swal.fire({
            title: "Algo sucedio!",
            text: "contacta con el administrador",
            icon: "error"
          });
        }
        this.dialogMediAplicados = false;
      }

      this.loading = false;
    },
    async agregarInsumos() {
      this.$v.formInsumos.$touch();
      this.loading = true;
      if (!this.$v.formInsumos.$error) {
        try {
          let data = null;
          var form = (this.isNewInsumos) ? this.formInsumos.concat(this.listInsumos) : this.formInsumos;
          if (this.isNewInsumos) {
            if (this.getPermiso("crearHistorias")) {

              data = await this.$apiService.create("cita/asistenciales/atender/create/insumos", form).then(data => data);

            }

          } else {
            if (this.getPermiso("editarHistorias")) {

              data = await this.$apiService.update("cita/asistenciales/atender/update/insumos/" + this.$route.params.id, form).then(data => data);

            }

          }
          if (JSON.stringify(form) !== JSON.stringify(this.listInsumos)) {
            if (data) {
              this.$swal.fire({
                title: "Completado!",
                text: "Registro exitoso",
                icon: "success",
                confirmButtonText: "Ok"
              });
              this.isNewInsumos = false;
              this.formInsumos = [];
            }
          }
          await this.cargarInsumos();
        } catch (error) {
          this.loading = false;
          this.$swal.fire({
            title: "Algo sucedio!",
            text: "contacta con el administrador",
            icon: "error"
          });
        }
        this.dialogInsumos = false;
      }

      this.loading = false;
    },
    async agregarOrdenes() {
      this.$v.formOrdenes.$touch();
      this.loading = true;
      if (!this.$v.formOrdenes.$error) {
        try {
          let data = null;
          var form = (this.isNewOrden) ? this.formOrdenes.concat(this.listOrdenes) : this.formOrdenes;
          if (this.isNewOrden) {
            if (this.getPermiso("crearHistorias")) {

              data = await this.$apiService.create("cita/asistenciales/atender/create/orden" + "?tipo=AUTORIZACION", form).then(data => data);

            }

          } else {

            if (this.getPermiso("editarHistorias")) {

              data = await this.$apiService.update("cita/asistenciales/atender/update/orden/" + this.$route.params.id + "?tipo=AUTORIZACION", form).then(data => data);
            }

          }
          if (JSON.stringify(form) !== JSON.stringify(this.listOrdenes)) {
            if (data) {

              this.$swal.fire({
                title: "Completado!",
                text: "Registro exitoso",
                icon: "success",
                confirmButtonText: "Ok"
              });
              this.isNewOrden = false;
              this.formOrdenes = [];
            }
          }
          await this.cargarOrdenes();
        } catch (error) {
          this.loading = false;
          this.$swal.fire({
            title: "Algo sucedio!",
            text: "contacta con el administrador",
            icon: "error"
          });
        }
        this.dialogOrdenes = false;
      }

      this.loading = false;
    },
    async agregarRemisiones() {
      this.$v.formRemisiones.$touch();
      this.loading = true;
      if (!this.$v.formRemisiones.$error) {
        try {
          let data = null;
          var form = (this.isNewRemision) ? this.formRemisiones.concat(this.listRemisiones) : this.formRemisiones;
          if (this.isNewRemision) {
            if (this.getPermiso("crearHistorias")) {

              data = await this.$apiService.create("cita/asistenciales/atender/create/remision/" + this.$route.params.id, form)
                .then(data => data);

            }

          } else {
            if (this.getPermiso("editarHistorias")) {

              data = await this.$apiService.update("cita/asistenciales/atender/update/remision/" + this.$route.params.id, form).then(data => data);

            }

          }
          if (JSON.stringify(form) !== JSON.stringify(this.listRemisiones)) {
            if (data) {

              this.$swal.fire({
                title: "Completado!",
                text: "Registro exitoso",
                icon: "success",
                confirmButtonText: "Ok"
              });
              this.isNewRemision = false;
              this.formRemisiones = [];
            }
          }
          await this.cargarRemisiones();
        } catch (error) {
          this.loading = false;
          this.$swal.fire({
            title: "Algo sucedio!",
            text: "contacta con el administrador",
            icon: "error"
          });
        }
        this.dialogRemisiones = false;
      }

      this.loading = false;
    },
    async agregarIncapacidades() {
      this.$v.formIncapacidades.$touch();
      this.loading = true;
      if (!this.$v.formIncapacidades.$error) {
        try {
          let data = null;
          var form = (this.isNewIncapacidad) ? this.formIncapacidades.concat(this.listIncapacidades) : this.formIncapacidades;
          if (this.isNewIncapacidad) {
            if (this.getPermiso("crearHistorias")) {

              data = await this.$apiService.create("cita/asistenciales/atender/create/incapacidadcertificado/" + this.$route.params.id, form)
                .then(data => data);
            }

          } else {
            if (this.getPermiso("editarHistorias")) {

              data = await this.$apiService.update("cita/asistenciales/atender/update/incapacidadcertificado/" + this.$route.params.id, form).then(data => data);
            }

          }
          if (JSON.stringify(form) !== JSON.stringify(this.listIncapacidades)) {
            if (data) {
              this.$swal.fire({
                title: "Completado!",
                text: "Registro exitoso",
                icon: "success",
                confirmButtonText: "Ok"
              });
              this.isNewIncapacidad = false;
              this.formIncapacidades = [];
            }
          }
          await this.cargarIncapacidadCertificado('INCAPACIDAD');
        } catch (error) {
          this.loading = false;
          this.$swal.fire({
            title: "Algo sucedio!",
            text: "contacta con el administrador",
            icon: "error"
          });
        }
        this.dialogIncapacidades = false;
      }

      this.loading = false;
    },
    async agregarCertificados() {
      this.$v.formCertificados.$touch();
      this.loading = true;
      if (!this.$v.formCertificados.$error) {
        try {
          let data = null;
          var form = (this.isNewCertificados) ? this.formCertificados.concat(this.listCertificados) : this.formCertificados;
          if (this.isNewCertificados) {
            if (this.getPermiso("crearHistorias")) {

              data = await this.$apiService
                .create("cita/asistenciales/atender/create/incapacidadcertificado/" + this.$route.params.id, form)
                .then(data => data);
            }
          } else {
            if (this.getPermiso("editarHistorias")) {

              data = await this.$apiService.update("cita/asistenciales/atender/update/incapacidadcertificado/" + this.$route.params.id, form).then(data => data);
            }
          }
          if (JSON.stringify(form) !== JSON.stringify(this.listCertificados)) {
            if (data) {

              this.$swal.fire({
                title: "Completado!",
                text: "Registro exitoso",
                icon: "success",
                confirmButtonText: "Ok"
              });
              this.isNewCertificados = false;
              this.formCertificados = [];
            }
          }
          await this.cargarIncapacidadCertificado('CERTIFICADO');
        } catch (error) {
          this.loading = false;
          this.$swal.fire({
            title: "Algo sucedio!",
            text: "contacta con el administrador",
            icon: "error"
          });
        }
        this.dialogCertificados = false;
      }

      this.loading = false;
    },
    async finalizar() {
      //await this.agregarDiagnosticos();
      if (this.listDiagnosticos.length > 0) {
        localStorage.removeItem("antencion");
        //this.$socket.emit('recargargraficas', { mensaje: 'grafica' });

        if (this.nuevo) {
          this.$socket.emit('cambiarturnos', {
            username: 'turnos',
            version:this.user.version,
            mensaje: 'historia'
          });
          this.nuevo = false;
          await this.$apiService.update("cita/facturaciones/horas/" + this.$route.params.id, {
            cifaHorafinal: new Date().hhmm()
          }).then(data => data);
          this.isNew = false;
        }
        this.$router.push({
          name: 'Atencionespanel',
          params: {
            id: this.factura.citaId
          }
        });
      } else {
        this.$swal.fire({
          title: "Debe llenar por lo menos un diagnostico!",
          text: "no se pudo finalizar la historia",
          icon: "error"
        });
      }

    },
    agregarIncapacidadCertificado(tipo) {
      tipo == 'INCAPACIDAD' ? this.formIncapacidades.push({
        ciceFechainicio: this.currentDate(new Date()),
        ciceFechafinal: this.currentDate(new Date()),
        ciceTipo: 'INCAPACIDAD',
        cifaId: this.$route.params.id,
        ciceDias: 0,
      }) : this.formCertificados.push({
        ciceTipo: 'CERTIFICADO',
        cifaId: this.$route.params.id,
        ciceCertificado: null,
      });
    },
    async eliminarIncapacidadCertificado(tipo, item) {
      let result = await this.$swal.fire({
        title: "Estas seguro?",
        text: "Esta seguro de que desea eliminar!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Si, eliminar!"
      }).then(result => result.value);
      if (result) {
        tipo == 'INCAPACIDAD' ? this.listIncapacidades = this.listIncapacidades.filter(e => e != item) : this.listCertificados = this.listCertificados.filter(e => e != item);
        tipo == 'INCAPACIDAD' ? this.formIncapacidades = this.listIncapacidades : this.formCertificados = this.listCertificados;
        tipo == 'INCAPACIDAD' ? this.agregarIncapacidades() : this.agregarCertificados();
      }

    },
    agregarOrden() {
      this.formOrdenes.push({
        ciorTipoOrden: null,
        ciorTipoProcedimiento: this.tipoprocedimiento.find(e => e.comaNombrecorto == 'AUTORIZACION').comaId,
        ciorObservacion: null,
        ciorCantidad:1,
        cifaId: this.$route.params.id,
        cocuId: null,
        cups: {
          search: null,
          isLoading: false,
          cups: []
        },
      });

    },
    async eliminarOrden(item) {
      let result = await this.$swal.fire({
        title: "Estas seguro?",
        text: "Esta seguro de que desea eliminar!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Si, eliminar!"
      }).then(result => result.value);
      if (result) {
        this.listOrdenes = this.listOrdenes.filter(
          e =>
          this.listOrdenes.indexOf(e) !=
          this.listOrdenes.indexOf(item)
        );
        this.formOrdenes = this.listOrdenes;
        this.agregarOrdenes();
      }
    },
    agregarOrdenrealizada() {
      this.formOrdenesrealizadas.push({
        ciorTipoOrden: null,
        ciorTipoProcedimiento: this.tipoprocedimiento.find(e => e.comaNombrecorto == 'REALIZADO').comaId,
        ciorObservacion: null,
        ciorCantidad:1,
        cifaId: this.$route.params.id,
        cocuId: null,
        cups: {
          search: null,
          isLoading: false,
          cups: []
        },
      });

    },
    async eliminarOrdenrealizada(item) {
      let result = await this.$swal.fire({
        title: "Estas seguro?",
        text: "Esta seguro de que desea eliminar!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Si, eliminar!"
      }).then(result => result.value);
      if (result) {
        this.listOrdenesrealizadas = this.listOrdenesrealizadas.filter(
          e =>
          this.listOrdenesrealizadas.indexOf(e) !=
          this.listOrdenesrealizadas.indexOf(item)
        );
        this.formOrdenesrealizadas = this.listOrdenesrealizadas;
        this.agregarOrdenesrealizadas();
      }
    },
    agregarMediAplicado() {
      this.formMediAplicados.push({
        cimaDescripcion: null,
        cimaCantidad: 1,
        mediId: null,
        cifaId: this.$route.params.id,
        citaId: this.factura.citaId
      });

    },
    async eliminarMediAplicado(item) {
      let result = await this.$swal.fire({
        title: "Estas seguro?",
        text: "Esta seguro de que desea eliminar!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Si, eliminar!"
      }).then(result => result.value);
      if (result) {
        this.listMediAplicados = this.listMediAplicados.filter(
          e =>
          this.listMediAplicados.indexOf(e) !=
          this.listMediAplicados.indexOf(item)
        );
        this.formMediAplicados= this.listMediAplicados;
        this.agregarMediAplicados();
      }
    },
    agregarInsumo() {
      this.formInsumos.push({
        insaFecha: new Date().yyyymmdd(),
        insaCantidad: 1,
        coinId: null,
        cifaId: this.$route.params.id,
        isSinstock:1
      });

    },
    async eliminarInsumo(item) {
      let result = await this.$swal.fire({
        title: "Estas seguro?",
        text: "Esta seguro de que desea eliminar!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Si, eliminar!"
      }).then(result => result.value);
      if (result) {
        this.listInsumos = this.listInsumos.filter(
          e =>
          this.listInsumos.indexOf(e) !=
          this.listInsumos.indexOf(item)
        );
        this.formInsumos= this.listInsumos;
        this.agregarInsumos();
      }
    },
    agregarDiagnostico() {
      this.formDiagnosticos.push({
        cidiDetalle: null,
        cidiTipoCie10: null,

        cidiTipoDiagnostico: null,

        cidiTipoClase: null,

        cifaId: this.$route.params.id,
        tipocie10: {
          search: null,
          isLoading: false,
          tipocie10: []
        }

      });
    },
    async eliminarDiagnostico(item) {
      let result = await this.$swal.fire({
        title: "Estas seguro?",
        text: "Esta seguro de que desea eliminar!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Si, eliminar!"
      }).then(result => result.value);
      if (result) {
        this.listDiagnosticos = this.listDiagnosticos.filter(
          e =>
          this.listDiagnosticos.indexOf(e) !=
          this.listDiagnosticos.indexOf(item)
        );
        this.formDiagnosticos = this.listDiagnosticos;
        this.agregarDiagnosticos();
      }
    },
    agregarRecetario() {
      this.formRecetarios.push({

        cireFrecuenciahoras: null,
        cireDetalles: null,
        cireDuraciondias: null,
        cireCantidaddosis: 1,
        cireCantidadprescrita: null,
        mediId: null,
        cifaId: this.$route.params.id,
        medicamento: null
      });
    },
    agregarMedicamento(item) {
      item.medicamento = {
        mediNombre: null,
        mediStock: 0,
        mediTipoVia: null,
        mediTipoPresentacion: null,
        mediPos: 0,
        mediFormafarmaceutica: null,
        mediConcentracion: null,
        mediTipoUnidad: null,
        mediActivo: 1,
      };
    },
    async eliminarMedicamento(item) {

      item.medicamento = null;
    },
    async eliminarRecetario(item) {
      let result = await this.$swal.fire({
        title: "Estas seguro?",
        text: "Esta seguro de que desea eliminar!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Si, eliminar!"
      }).then(result => result.value);
      if (result) {
        this.listRecetarios = this.listRecetarios.filter(
          e =>
          this.listRecetarios.indexOf(e) !=
          this.listRecetarios.indexOf(item)
        );
        this.formRecetarios = this.listRecetarios;
        this.agregarRecetarios();
      }
    },
    agregarRemision() {
      this.formRemisiones.push({
        cirmId: null,
        cirmObservacion: null,

        cocuId: null,

        cifaId: this.$route.params.id,
        cups: {
          search: null,
          isLoading: false,
          cups: []
        },
      });
    },
    async eliminarRemision(item) {
      let result = await this.$swal.fire({
        title: "Estas seguro?",
        text: "Esta seguro de que desea eliminar!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Si, eliminar!"
      }).then(result => result.value);
      if (result) {
        this.listRemisiones = this.listRemisiones.filter(
          e =>
          this.listRemisiones.indexOf(e) !=
          this.listRemisiones.indexOf(item)
        );
        this.formRemisiones = this.listRemisiones;
        this.agregarRemisiones();
      }
    },
    async eliminarIncapacidadCertificadoForm(tipo, item) {
      let result = await this.$swal.fire({
        title: "Estas seguro?",
        text: "Esta seguro de que desea eliminar!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Si, eliminar!"
      }).then(result => result.value);
      if (result) {
        tipo == 'INCAPACIDAD' ? this.formIncapacidades = this.formIncapacidades.filter(e => e != item) : this.formCertificados = this.formCertificados.filter(e => e != item);

      }

    },
    async eliminarOrdenForm(item) {
      let result = await this.$swal.fire({
        title: "Estas seguro?",
        text: "Esta seguro de que desea eliminar!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Si, eliminar!"
      }).then(result => result.value);
      if (result) {
        this.formOrdenes = this.formOrdenes.filter(
          e =>
          this.formOrdenes.indexOf(e) !=
          this.formOrdenes.indexOf(item)
        );
      }
    },
    async eliminarOrdenrealizadaForm(item) {
      let result = await this.$swal.fire({
        title: "Estas seguro?",
        text: "Esta seguro de que desea eliminar!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Si, eliminar!"
      }).then(result => result.value);
      if (result) {
        this.formOrdenesrealizadas = this.formOrdenesrealizadas.filter(
          e =>
          this.formOrdenesrealizadas.indexOf(e) !=
          this.formOrdenesrealizadas.indexOf(item)
        );
      }
    },
    async eliminarDiagnosticoForm(item) {
      let result = await this.$swal.fire({
        title: "Estas seguro?",
        text: "Esta seguro de que desea eliminar!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Si, eliminar!"
      }).then(result => result.value);
      if (result) {
        this.formDiagnosticos = this.formDiagnosticos.filter(
          e =>
          this.formDiagnosticos.indexOf(e) !=
          this.formDiagnosticos.indexOf(item)
        );
      }
    },
    async eliminarRecetarioForm(item) {
      let result = await this.$swal.fire({
        title: "Estas seguro?",
        text: "Esta seguro de que desea eliminar!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Si, eliminar!"
      }).then(result => result.value);
      if (result) {
        this.formRecetarios = this.formRecetarios.filter(
          e =>
          this.formRecetarios.indexOf(e) !=
          this.formRecetarios.indexOf(item)
        );
      }
    },
    async eliminarRemisionForm(item) {
      let result = await this.$swal.fire({
        title: "Estas seguro?",
        text: "Esta seguro de que desea eliminar!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Si, eliminar!"
      }).then(result => result.value);
      if (result) {
        this.formRemisiones = this.formRemisiones.filter(
          e =>
          this.formRemisiones.indexOf(e) !=
          this.formRemisiones.indexOf(item)
        );
      }
    },
    escojerPaquete(item, tipo) {
      if (tipo == 'Ordenes') {
        this.formOrdenes = [];
        for (let cup of item.tblConfPaquetescups) {
          this.formOrdenes.push({
            ciorTipoOrden: cup.copoTipoOrden,
            ciorTipoProcedimiento: this.tipoprocedimiento.find(e => e.comaNombrecorto == 'AUTORIZACION').comaId,
            ciorObservacion: null,
            ciorCantidad:1,
            cifaId: this.$route.params.id,
            cocuId: cup.tblConfCup.cocuId,
            cups: {
              search: null,
              isLoading: false,
              cups: [cup.tblConfCup]
            },
          });
        }
        this.dialogPaqueteOrdenes = false;
        this.dialogOrdenes = true;
      }
      if (tipo == 'ProcesosRealizados') {
        this.formOrdenesrealizadas = [];
        for (let cup of item.tblConfPaquetescups) {
          this.formOrdenesrealizadas.push({
            ciorTipoOrden: cup.copoTipoOrden,
            ciorTipoProcedimiento: this.tipoprocedimiento.find(e => e.comaNombrecorto == 'REALIZADO').comaId,
            ciorObservacion: null,
            ciorCantidad:1,
            cifaId: this.$route.params.id,
            cocuId: cup.tblConfCup.cocuId,
            cups: {
              search: null,
              isLoading: false,
              cups: [cup.tblConfCup]
            },
          });
        }
        this.dialogPaqueteProcesosRealizados = false;
        this.dialogProcesosRealizados = true;
      }
    },
    agregarDiente() {
      this.formOdontograma.push({
        ciodConvencion: null,
        ciodDiente: null,
        ciodParte: null,
        cifaId: this.factura.cifaId,
        ciodPlaca: 0,
        ciodTipo: 1
      });
    },
    agregarDientePlaca() {
      this.formPlaca.push({
        ciodDiente: null,
        ciodParte: null,
        cifaId: this.factura.cifaId,
        ciodPlaca: 1,
        ciodTipo: 0
      });
    },
    async eliminarDiente(item) {

      this.formOdontograma = this.formOdontograma.filter(e =>
        this.formOdontograma.indexOf(e) !=
        this.formOdontograma.indexOf(item)
      );

    },
    async eliminarDientePlaca(item) {

      this.formPlaca = this.formPlaca.filter(e =>
        this.formPlaca.indexOf(e) !=
        this.formPlaca.indexOf(item)
      );

    },
    limpiar() {
      this.formHistoria = [];
      this.formOrdenes = [];
      this.formDiagnosticos = [];
      this.formRecetarios = [];
      this.formRemisiones = [];
    }
  }
};

</script>

<style scoped>
.bg-tab{
  background-color:  rgb(220, 223, 224)  !important;
}
#scroll-historia {
  max-height: 700 px !important;
  overflow: auto;
}

.list-enter-active,
.list-leave-active {
  transition: all 0.5s;
}

.list-enter,
.list-leave-to

/* .list-leave-active below VERSION 2.1.8 */
  {
  opacity: 0;
  transform: translateX(20px);
}

.convencion {
  cursor: pointer
}

.convencion:hover {
  background-color: #688cf03d;
}

</style>
