<template>
<v-card class="mb-12">
    <v-container>
        <v-alert text prominent dense type="error" v-if="mensaje">
            {{mensaje}}
        </v-alert>
        <template v-if="mensaje == null && secciones.length > 0">
            <v-row v-for="seccion in  secciones" :key="seccion.hiseId">
                <v-col cols="12" sm="12" md="12" class="primary--text">
                    <h3>{{seccion.hiseNombre}}</h3>
                </v-col>
                <v-col cols="12" sm="12" md="12" :key="seccion.hiseId" class="primary--text" v-if="seccion.hiseHistorico">
                    {{cifaFecha}}
                </v-col>
                <template v-for="c in  seccion.campos">
                    <v-col cols="12" sm="12" :md="c.tblConfHistoriaseccione.hiseCol" :key="'c'+c.cihfId">
                        <div v-if="c.tblConfHistoriaseccione.tipodato.comaNombrecorto == 'LISTA' || c.tblConfHistoriaseccione.tipodato.comaNombrecorto == 'SI/NO' && seccion.hiseCalculado">
                            <span class="black--text">{{c.tblConfHistoriaseccione.hiseNombre}}</span>
                            <span class="grey--text caption pa-5">{{`${getValues(c.tblConfHistoriaseccione.hiseValores, c.cihfValor)}(${c.cihfValor})`}}</span>
                        </div>
                        <div v-else>
                            <span class="black--text">{{c.tblConfHistoriaseccione.hiseNombre}}</span>
                            <span class="grey--text caption pa-5">{{c.cihfValor}}</span>
                        </div>
                    </v-col>
                </template>
                <template v-if="seccion.hiseHistorico">
                    <template v-for="(c,index) in  historial.filter(e => e.tblConfHistoriaseccione.hiseIdDependencia == seccion.hiseId)">
                        <v-col cols="12" sm="12" md="12" class="primary--text" v-if="index == 0 || historial.filter(e => e.tblConfHistoriaseccione.hiseIdDependencia == seccion.hiseId)[(index > 0 )?index - 1:index].tblCitaFacturacione.cifaId > c.tblCitaFacturacione.cifaId " :key="'hf'+index  +c.tblCitaFacturacione.cifaId">
                            {{c.tblCitaFacturacione.cifaFecha }}
                        </v-col>
                        <v-col cols="12" sm="12" :md="c.hiseCol" :key="'hc'+c.tblCitaFacturacione.cifaId + index">
                            <div v-if="c.tblConfHistoriaseccione.tipodato.comaNombrecorto == 'LISTA' || c.tblConfHistoriaseccione.tipodato.comaNombrecorto == 'SI/NO' && seccion.hiseCalculado">
                                <span class="black--text">{{c.hiseNombre}}</span>
                                <span class="grey--text caption pa-5">{{`${getValues(c.tblConfHistoriaseccione.hiseValores, c.cihfValor)}(${c.cihfValor})`}}</span>
                            </div>
                            <div v-else>
                                <span class="black--text">{{c.tblConfHistoriaseccione.hiseNombre}}</span>
                                <span class="grey--text caption pa-5">{{c.cihfValor}}</span>
                            </div>
                        </v-col>
                    </template>
                </template>
            </v-row>
            <v-row>
                <v-col cols="12" sm="12" md="12" class="primary--text" v-if="dientesplaca.length > 0">
                    <h3>Control placa</h3>
                    <img width="800" id="imgplaca">
                    <div id="contenedorcanvasplaca">
                        <canvas id="canvasplaca"></canvas>
                    </div>
                </v-col>
                <v-col cols="12" sm="12" md="12" class="primary--text" v-if="primerOdontograma.length > 0">
                    <h3>Primer odontograma</h3>
                    <img width="800" id="imgprimerodontograma">
                    <div id="contenedorcanvasprimerodontograma">
                        <canvas id="canvasprimerodontograma"></canvas>
                    </div>

                </v-col>
                <v-col cols="12" sm="12" md="12" class="primary--text" v-if="dientesodontograma.length > 0">
                    <h3>Odontograma actual</h3>
                    <img width="800" id="imgodontograma">
                    <div id="contenedorcanvasodontograma">
                        <canvas id="canvasodontograma"></canvas>
                    </div>
                </v-col>
            </v-row>
        </template>
    </v-container>
</v-card>
</template>

<script>
import {
    fabric
} from "fabric";

import generales from '../../mixins/generales.js';
export default {
    props: {
        datos: Object,
    },
     mixins: [generales],
    data() {
        return {
            menu: false,
            tab: "tab-1",
            url: "sistema/historiasfacturaciones/",
            factura: null,
            secciones: [],
            campos: [],
            Historia: [],
            historial: [],
            cifaFecha: null,
            mensaje: null,
            tipocampos: [],
            dientesplaca: [],
            primerOdontograma: [],
            dientesodontograma: [],
            convenciones: [],
            canvasplaca: null,
            odontogramacanvas: null,
            primerodontogramacanvas: null,
            odontogramacargado: false,
        };
    },
    created: async function () {
        if (this.getPermiso("verHistorias")) {
            
            this.convenciones = this.$config.convenciones;
            this.$store.commit('setCargaDatos', true);
            if (this.datos) {
                if (this.datos.secciones.length > 0) {
                    this.secciones = this.datos.secciones
                    this.dientesplaca = this.datos.dientesplaca
                    this.primerOdontograma = this.datos.primerOdontograma
                    this.dientesodontograma = this.datos.dientesodontograma
                    this.historial = this.datos.historial
                    this.cifaFecha = this.datos.cifaFecha
                    this.mensaje = null;
                } else {
                    this.mensaje = "La historia aun no ha sido tramitada";
                }
            } else {
                this.mensaje = "No se a facturado";
            }
            this.$store.commit('setCargaDatos', false);
        }

    },
    updated: function () {
        if (
            document.getElementById("canvasplaca") &&
            this.dientesodontograma != undefined
        ) {
            if (this.dientesodontograma && this.odontogramacargado == false) {
                this.odontogramacargado = true;
                if (this.dientesplaca.length > 0) {
                    this.cargarplaca("#canvasplaca", this.dientesplaca);
                }
                if (this.primerOdontograma.length > 0) {
                    this.cargarodontograma(
                        "#canvasprimerodontograma",
                        this.primerOdontograma,
                        "primero"
                    );
                }
                if (this.dientesodontograma.length > 0) {
                    this.cargarodontograma(
                        "#canvasodontograma",
                        this.dientesodontograma,
                        "actual"
                    );
                }

                window.setTimeout(() => {
                    if (this.dientesplaca.length > 0) {
                        let imgcanvasplaca = document.getElementById("imgplaca");
                        let canvasplaca = document.getElementById("contenedorcanvasplaca");
                        imgcanvasplaca.removeAttribute("hidden");
                        imgcanvasplaca.setAttribute(
                            "src",
                            this.canvasplaca.toDataURL("image/png")
                        );
                        canvasplaca.setAttribute("hidden", true);
                    }
                    if (this.primerOdontograma.length > 0) {
                        let imgprimerodontogramacanvas = document.getElementById(
                            "imgprimerodontograma"
                        );
                        let primerodontogramacanvas = document.getElementById(
                            "contenedorcanvasprimerodontograma"
                        );
                        imgprimerodontogramacanvas.removeAttribute("hidden");
                        imgprimerodontogramacanvas.setAttribute(
                            "src",
                            this.primerodontogramacanvas.toDataURL("image/png")
                        );
                        primerodontogramacanvas.setAttribute("hidden", true);
                    }
                    if (this.dientesodontograma.length > 0) {
                        let imgodontogramacanvas = document.getElementById(
                            "imgodontograma"
                        );
                        let odontogramacanvas = document.getElementById(
                            "contenedorcanvasodontograma"
                        );
                        imgodontogramacanvas.removeAttribute("hidden");
                        imgodontogramacanvas.setAttribute(
                            "src",
                            this.odontogramacanvas.toDataURL("image/png")
                        );
                        odontogramacanvas.setAttribute("hidden", true);
                    }
                }, 1000);
            }
        }
    },
    methods: {
        getValues(values, valor) {
            const lista = [];
            if (values) {
                var datos = values.split('|');
                for (let i = 0; i < datos.length; i++) {
                    let d = datos[i];
                    lista.push({
                        texts: d.split(';')[0],
                        values: d.split(';')[1]
                    });
                }
                return (lista.filter(e => e.values == valor).length > 0) ? lista.filter(e => e.values == valor)[0].texts : '';
            }
            return '';
        },
        generarDientesplaca(t, l, ini, fin, valor, tipo, tipo2) {
            let dientes = [];
            let left = 0;
            for (let i = ini; i < fin; i++) {
                let parteTop = {
                    numero: valor,
                    convencion: null,
                    id: "parte6-diente-" + valor,
                    name: "parte6",
                    backgroundColor: "#FFFFFF",
                    top: t + 63,
                    left: l + 53,

                };
                let parteBottom = {
                    numero: valor,
                    convencion: null,
                    id: "parte7-diente-" + valor,
                    name: "parte7",
                    backgroundColor: "#FFFFFF",
                    top: t + 113,
                    left: l + 53
                };
                let parteLeftTop = {
                    numero: valor,
                    placa: 0,
                    convencion: null,
                    id: "parte1-diente-" + valor,
                    name: "parte1",
                    backgroundColor: "#FFFFFF",
                    top: t + 88,
                    left: l + 38
                };
                let parteRightTop = {
                    numero: valor,
                    placa: 0,
                    convencion: null,
                    id: "parte2-diente-" + valor,
                    name: "parte2",
                    backgroundColor: "#FFFFFF",
                    top: t + 73,
                    left: l + 53
                };
                let parteLeftBottom = {
                    numero: valor,
                    placa: 0,
                    convencion: null,
                    id: "parte3-diente-" + valor,
                    name: "parte3",
                    backgroundColor: "#FFFFFF",
                    top: t + 103,
                    left: l + 53
                };
                let parteRightBottom = {
                    numero: valor,
                    placa: 0,
                    convencion: null,
                    id: "parte4-diente-" + valor,
                    name: "parte4",
                    backgroundColor: "#FFFFFF",
                    top: t + 88,
                    left: l + 68
                };
                let parteMedio = {
                    numero: valor,
                    placa: 0,
                    convencion: null,
                    id: "parte5-diente-" + valor,
                    name: "parte5",
                    backgroundColor: "#FFFFFF",
                    top: t + 88,
                    left: l + 53
                };

                if (i > ini) {
                    left = 70;

                    parteTop.left = parteTop.left + left * (i - ini);
                    parteBottom.left = parteBottom.left + left * (i - ini);
                    parteLeftTop.left = parteLeftTop.left + left * (i - ini);
                    parteRightTop.left = parteRightTop.left + left * (i - ini);
                    parteLeftBottom.left = parteLeftBottom.left + left * (i - ini);
                    parteRightBottom.left = parteRightBottom.left + left * (i - ini);
                    parteMedio.left = parteMedio.left + left * (i - ini);
                }

                dientes.push({
                    id: "diente-" + valor,
                    convencion: null,
                    numero: valor,
                    partes: (tipo2 == 'PLACA') ? {
                        //parteTop: parteTop,
                        //parteBottom: parteBottom,
                        parteLeftTop: parteLeftTop,
                        parteRightTop: parteRightTop,
                        parteLeftBottom: parteLeftBottom,
                        parteRightBottom: parteRightBottom,
                        parteMedio: parteMedio
                    } : {
                        parteTop: parteTop,
                        parteBottom: parteBottom,
                        parteLeftTop: parteLeftTop,
                        parteRightTop: parteRightTop,
                        parteLeftBottom: parteLeftBottom,
                        parteRightBottom: parteRightBottom,
                        parteMedio: parteMedio
                    }
                });
                if (tipo == 'negativo') {
                    valor = valor - 1;
                } else {
                    valor = valor + 1;
                }

            }
            return dientes;
        },
        generarDientes(tipo) {
            let dientes = [];
            dientes = dientes.concat(this.generarDientesplaca(0, 0, 0, 8, 18, 'negativo', tipo));
            dientes = dientes.concat(this.generarDientesplaca(0, 590, 8, 16, 21, 'positivo', tipo));
            dientes = dientes.concat(this.generarDientesplaca(100, 210, 16, 21, 55, 'negativo', tipo));
            dientes = dientes.concat(this.generarDientesplaca(100, 590, 21, 26, 61, 'positivo', tipo));
            dientes = dientes.concat(this.generarDientesplaca(200, 210, 26, 31, 85, 'negativo', tipo));
            dientes = dientes.concat(this.generarDientesplaca(200, 590, 31, 36, 71, 'positivo', tipo));
            dientes = dientes.concat(this.generarDientesplaca(300, 0, 36, 44, 48, 'negativo', tipo));
            dientes = dientes.concat(this.generarDientesplaca(300, 590, 44, 52, 31, 'positivo', tipo));
            return dientes;
        },
        cargarodontograma(nombre, dientesplaca, tipo) {
            let canvas = new fabric.Canvas(document.querySelector(nombre), {
                height: 500,
                width: 1200,
                selectionColor: "#90ccb7",
                backgroundColor: "#fff"
            });

            dientesplaca.forEach(diente => {
                var text = new fabric.Text(diente.numero + "", {
                    left: diente.partes.parteRightTop.left - 8,
                    top: diente.partes.parteRightTop.top - 40,
                    fontSize: 12
                });
                canvas.add(text);
                var rect1 = new fabric.Rect({
                    hoverCursor: "pointer",
                    originX: "center",
                    originY: "center",
                    angle: 45,
                    selectable: false,
                    stroke: "#000000",
                    width: 20,
                    height: 20,
                    left: diente.partes.parteLeftTop.left,
                    top: diente.partes.parteLeftTop.top,
                    lockMovementX: true,
                    lockMovementY: true,
                    lockRotation: true,
                    lockScalingX: true,
                    lockScalingY: true,
                    lockSkewingX: true,
                    lockSkewingY: true,
                    fill: diente.partes.parteLeftTop.backgroundColor,
                    id: diente.partes.parteLeftTop.id
                });
                var rect2 = new fabric.Rect({
                    hoverCursor: "pointer",
                    originX: "center",
                    originY: "center",
                    angle: 45,
                    selectable: false,
                    stroke: "#000000",
                    width: 20,
                    height: 20,
                    left: diente.partes.parteRightTop.left,
                    top: diente.partes.parteRightTop.top,
                    lockMovementX: true,
                    lockMovementY: true,
                    lockRotation: true,
                    lockScalingX: true,
                    lockScalingY: true,
                    lockSkewingX: true,
                    lockSkewingY: true,
                    fill: diente.partes.parteRightTop.backgroundColor,
                    id: diente.partes.parteRightTop.id
                });
                var rect3 = new fabric.Rect({
                    hoverCursor: "pointer",
                    originX: "center",
                    originY: "center",
                    angle: 45,
                    selectable: false,
                    stroke: "#000000",
                    width: 20,
                    height: 20,
                    left: diente.partes.parteLeftBottom.left,
                    top: diente.partes.parteLeftBottom.top,
                    lockMovementX: true,
                    lockMovementY: true,
                    lockRotation: true,
                    lockScalingX: true,
                    lockScalingY: true,
                    lockSkewingX: true,
                    lockSkewingY: true,
                    fill: diente.partes.parteLeftBottom.backgroundColor,
                    id: diente.partes.parteLeftBottom.id
                });
                var rect4 = new fabric.Rect({
                    hoverCursor: "pointer",
                    originX: "center",
                    originY: "center",
                    angle: 45,
                    selectable: false,
                    stroke: "#000000",
                    width: 20,
                    height: 20,
                    left: diente.partes.parteRightBottom.left,
                    top: diente.partes.parteRightBottom.top,
                    lockMovementX: true,
                    lockMovementY: true,
                    lockRotation: true,
                    lockScalingX: true,
                    lockScalingY: true,
                    lockSkewingX: true,
                    lockSkewingY: true,
                    fill: diente.partes.parteRightBottom.backgroundColor,
                    id: diente.partes.parteRightBottom.id
                });
                var circulo = new fabric.Circle({
                    hoverCursor: "pointer",
                    originX: "center",
                    originY: "center",
                    stroke: "#000000",
                    selectable: false,
                    absolutePositioned: true,
                    radius: 12,
                    left: diente.partes.parteMedio.left,
                    top: diente.partes.parteMedio.top,
                    lockMovementX: true,
                    lockMovementY: true,
                    lockRotation: true,
                    lockScalingX: true,
                    lockScalingY: true,
                    lockSkewingX: true,
                    lockSkewingY: true,
                    fill: diente.partes.parteMedio.backgroundColor,
                    id: diente.partes.parteMedio.id
                });
                var rect6 = new fabric.Rect({
                    hoverCursor: "pointer",
                    originX: "center",
                    originY: "center",
                    selectable: false,
                    stroke: "#000000",
                    width: 30,
                    height: 30,
                    left: diente.partes.parteTop.left,
                    top: diente.partes.parteTop.top,
                    lockMovementX: true,
                    lockMovementY: true,
                    lockRotation: true,
                    lockScalingX: true,
                    lockScalingY: true,
                    lockSkewingX: true,
                    lockSkewingY: true,
                    fill: diente.partes.parteTop.backgroundColor,
                    id: diente.partes.parteTop.id
                });
                var rect7 = new fabric.Rect({
                    hoverCursor: "pointer",
                    originX: "center",
                    originY: "center",
                    selectable: false,
                    stroke: "#000000",
                    width: 30,
                    height: 30,
                    left: diente.partes.parteBottom.left,
                    top: diente.partes.parteBottom.top,
                    lockMovementX: true,
                    lockMovementY: true,
                    lockRotation: true,
                    lockScalingX: true,
                    lockScalingY: true,
                    lockSkewingX: true,
                    lockSkewingY: true,
                    fill: diente.partes.parteBottom.backgroundColor,
                    id: diente.partes.parteBottom.id
                });
                canvas.add(rect6);
                canvas.add(rect7);
                canvas.add(rect1);
                canvas.add(rect2);
                canvas.add(rect3);
                canvas.add(rect4);
                canvas.add(circulo);
                if (diente.convencion) {
                    let img = new Image();
                    img.src = this.convenciones.find(
                        e => e.value == diente.convencion
                    ).img;
                    var convencion = new fabric.Image(img, {
                        left: diente.partes.parteMedio.left - 38,
                        top: diente.partes.parteMedio.top - 38,
                        scaleX: 0.15,
                        scaleY: 0.15,
                        hoverCursor: "pointer",
                        selectable: false,
                        lockMovementX: true,
                        lockMovementY: true,
                        lockRotation: true,
                        lockScalingX: true,
                        lockScalingY: true,
                        lockSkewingX: true,
                        lockSkewingY: true
                    });
                    canvas.add(convencion).renderAll();
                    canvas.setActiveObject(convencion);
                }

                if (diente.partes.parteTop.convencion) {
                    let img = new Image();
                    img.src = this.convenciones.find(
                        e => e.value == diente.partes.parteTop.convencion
                    ).img;
                    var parteTop = new fabric.Image(img, {
                        left: diente.partes.parteTop.left - 12,
                        top: diente.partes.parteTop.top - 13,
                        scaleX: 0.05,
                        scaleY: 0.05,
                        hoverCursor: "pointer",
                        selectable: false,
                        lockMovementX: true,
                        lockMovementY: true,
                        lockRotation: true,
                        lockScalingX: true,
                        lockScalingY: true,
                        lockSkewingX: true,
                        lockSkewingY: true
                    });
                    canvas.add(parteTop).renderAll();
                    canvas.setActiveObject(parteTop);
                }
                if (diente.partes.parteBottom.convencion) {
                    let img = new Image();
                    img.src = this.convenciones.find(
                        e => e.value == diente.partes.parteBottom.convencion
                    ).img;
                    var parteBottom = new fabric.Image(img, {
                        left: diente.partes.parteBottom.left - 12,
                        top: diente.partes.parteBottom.top - 13,
                        scaleX: 0.05,
                        scaleY: 0.05,
                        hoverCursor: "pointer",
                        selectable: false,
                        lockMovementX: true,
                        lockMovementY: true,
                        lockRotation: true,
                        lockScalingX: true,
                        lockScalingY: true,
                        lockSkewingX: true,
                        lockSkewingY: true
                    });
                    canvas.add(parteBottom).renderAll();
                    canvas.setActiveObject(parteBottom);
                }
                if (diente.partes.parteLeftTop.convencion) {
                    let img = new Image();
                    img.src = this.convenciones.find(
                        e => e.value == diente.partes.parteLeftTop.convencion
                    ).img;
                    var parteLeftTop = new fabric.Image(img, {
                        left: diente.partes.parteLeftTop.left - 12,
                        top: diente.partes.parteLeftTop.top - 13,
                        scaleX: 0.05,
                        scaleY: 0.05,
                        hoverCursor: "pointer",
                        selectable: false,
                        lockMovementX: true,
                        lockMovementY: true,
                        lockRotation: true,
                        lockScalingX: true,
                        lockScalingY: true,
                        lockSkewingX: true,
                        lockSkewingY: true
                    });
                    canvas.add(parteLeftTop).renderAll();
                    canvas.setActiveObject(parteLeftTop);
                }
                if (diente.partes.parteRightTop.convencion) {
                    let img = new Image();
                    img.src = this.convenciones.find(
                        e => e.value == diente.partes.parteRightTop.convencion
                    ).img;
                    var parteRightTop = new fabric.Image(img, {
                        left: diente.partes.parteRightTop.left - 12,
                        top: diente.partes.parteRightTop.top - 13,
                        scaleX: 0.05,
                        scaleY: 0.05,
                        hoverCursor: "pointer",
                        selectable: false,
                        lockMovementX: true,
                        lockMovementY: true,
                        lockRotation: true,
                        lockScalingX: true,
                        lockScalingY: true,
                        lockSkewingX: true,
                        lockSkewingY: true
                    });
                    canvas.add(parteRightTop).renderAll();
                    canvas.setActiveObject(parteRightTop);
                }
                if (diente.partes.parteLeftBottom.convencion) {
                    let img = new Image();
                    img.src = this.convenciones.find(
                        e => e.value == diente.partes.parteLeftBottom.convencion
                    ).img;
                    var parteLeftBottom = new fabric.Image(img, {
                        left: diente.partes.parteLeftBottom.left - 12,
                        top: diente.partes.parteLeftBottom.top - 13,
                        scaleX: 0.05,
                        scaleY: 0.05,
                        hoverCursor: "pointer",
                        selectable: false,
                        lockMovementX: true,
                        lockMovementY: true,
                        lockRotation: true,
                        lockScalingX: true,
                        lockScalingY: true,
                        lockSkewingX: true,
                        lockSkewingY: true
                    });
                    canvas.add(parteLeftBottom).renderAll();
                    canvas.setActiveObject(parteLeftBottom);
                }
                if (diente.partes.parteRightBottom.convencion) {
                    let img = new Image();
                    img.src = this.convenciones.find(
                        e => e.value == diente.partes.parteRightBottom.convencion
                    ).img;
                    var parteRightBottom = new fabric.Image(img, {
                        left: diente.partes.parteRightBottom.left - 12,
                        top: diente.partes.parteRightBottom.top - 13,
                        scaleX: 0.05,
                        scaleY: 0.05,
                        hoverCursor: "pointer",
                        selectable: false,
                        lockMovementX: true,
                        lockMovementY: true,
                        lockRotation: true,
                        lockScalingX: true,
                        lockScalingY: true,
                        lockSkewingX: true,
                        lockSkewingY: true
                    });
                    canvas.add(parteRightBottom).renderAll();
                    canvas.setActiveObject(parteRightBottom);
                }
                if (diente.partes.parteMedio.convencion) {
                    let img = new Image();
                    img.src = this.convenciones.find(
                        e => e.value == diente.partes.parteMedio.convencion
                    ).img;
                    var parteMedio = new fabric.Image(img, {
                        left: diente.partes.parteMedio.left - 12,
                        top: diente.partes.parteMedio.top - 13,
                        scaleX: 0.05,
                        scaleY: 0.05,
                        hoverCursor: "pointer",
                        selectable: false,
                        lockMovementX: true,
                        lockMovementY: true,
                        lockRotation: true,
                        lockScalingX: true,
                        lockScalingY: true,
                        lockSkewingX: true,
                        lockSkewingY: true
                    });
                    canvas.add(parteMedio).renderAll();
                    canvas.setActiveObject(parteMedio);
                }
                //canvas.renderAll();
            });
            if (tipo == "actual") {
                this.odontogramacanvas = canvas;
            } else {
                this.primerodontogramacanvas = canvas;
            }
        },
        cargarplaca(nombre, dientesplaca) {
            this.canvasplaca = new fabric.Canvas(document.querySelector(nombre), {
                height: 500,
                width: 1200,
                selectionColor: "#90ccb7",
                backgroundColor: "#fff"
            });

            dientesplaca.forEach(diente => {
                var text = new fabric.Text(diente.numero + "", {
                    left: diente.partes.parteRightTop.left - 10,
                    top: diente.partes.parteRightTop.top - 35,
                    fontSize: 12
                });
                this.canvasplaca.add(text);
                var rect1 = new fabric.Rect({
                    hoverCursor: "pointer",
                    originX: "center",
                    originY: "center",
                    angle: 45,
                    selectable: false,
                    stroke: "#000000",
                    width: 20,
                    height: 20,
                    left: diente.partes.parteLeftTop.left,
                    top: diente.partes.parteLeftTop.top,
                    lockMovementX: true,
                    lockMovementY: true,
                    lockRotation: true,
                    lockScalingX: true,
                    lockScalingY: true,
                    lockSkewingX: true,
                    lockSkewingY: true,
                    fill: diente.partes.parteLeftTop.placa ?
                        "#FF0000" : diente.partes.parteLeftTop.backgroundColor,
                    id: diente.partes.parteLeftTop.id
                });
                var rect2 = new fabric.Rect({
                    hoverCursor: "pointer",
                    originX: "center",
                    originY: "center",
                    angle: 45,
                    selectable: false,
                    stroke: "#000000",
                    width: 20,
                    height: 20,
                    left: diente.partes.parteRightTop.left,
                    top: diente.partes.parteRightTop.top,
                    lockMovementX: true,
                    lockMovementY: true,
                    lockRotation: true,
                    lockScalingX: true,
                    lockScalingY: true,
                    lockSkewingX: true,
                    lockSkewingY: true,
                    fill: diente.partes.parteRightTop.placa ?
                        "#FF0000" : diente.partes.parteRightTop.backgroundColor,
                    id: diente.partes.parteRightTop.id
                });
                var rect3 = new fabric.Rect({
                    hoverCursor: "pointer",
                    originX: "center",
                    originY: "center",
                    angle: 45,
                    selectable: false,
                    stroke: "#000000",
                    width: 20,
                    height: 20,
                    left: diente.partes.parteLeftBottom.left,
                    top: diente.partes.parteLeftBottom.top,
                    lockMovementX: true,
                    lockMovementY: true,
                    lockRotation: true,
                    lockScalingX: true,
                    lockScalingY: true,
                    lockSkewingX: true,
                    lockSkewingY: true,
                    fill: diente.partes.parteLeftBottom.placa ?
                        "#FF0000" : diente.partes.parteLeftBottom.backgroundColor,
                    id: diente.partes.parteLeftBottom.id
                });
                var rect4 = new fabric.Rect({
                    hoverCursor: "pointer",
                    originX: "center",
                    originY: "center",
                    angle: 45,
                    selectable: false,
                    stroke: "#000000",
                    width: 20,
                    height: 20,
                    left: diente.partes.parteRightBottom.left,
                    top: diente.partes.parteRightBottom.top,
                    lockMovementX: true,
                    lockMovementY: true,
                    lockRotation: true,
                    lockScalingX: true,
                    lockScalingY: true,
                    lockSkewingX: true,
                    lockSkewingY: true,
                    fill: diente.partes.parteRightBottom.placa ?
                        "#FF0000" : diente.partes.parteRightBottom.backgroundColor,
                    id: diente.partes.parteRightBottom.id
                });
                var circulo = new fabric.Circle({
                    hoverCursor: "pointer",
                    originX: "center",
                    originY: "center",
                    stroke: "#000000",
                    selectable: false,
                    absolutePositioned: true,
                    radius: 12,
                    left: diente.partes.parteMedio.left,
                    top: diente.partes.parteMedio.top,
                    lockMovementX: true,
                    lockMovementY: true,
                    lockRotation: true,
                    lockScalingX: true,
                    lockScalingY: true,
                    lockSkewingX: true,
                    lockSkewingY: true,
                    fill: diente.partes.parteMedio.placa ?
                        "#FF0000" : diente.partes.parteMedio.backgroundColor,
                    id: diente.partes.parteMedio.id
                });
                this.canvasplaca.add(rect1);
                this.canvasplaca.add(rect2);
                this.canvasplaca.add(rect3);
                this.canvasplaca.add(rect4);
                this.canvasplaca.add(circulo);
            });
        },
    }
};
</script>

<style>

</style>
